import React from 'react';
import axios from 'axios';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons'
import { faShare } from '@fortawesome/free-solid-svg-icons'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { faPauseCircle } from '@fortawesome/free-solid-svg-icons'

import Auxi from '../../hoc/Auxi';
import Spinner from '../../components/Widgets/Spinner';
// import AppLayout from './CustomAppTabLayout';
import { toast } from 'react-toastify';
import Modal from '../../components/Widgets/Modal/Modal'
import AudioPlayer from 'react-h5-audio-player';
import AppLayout from './CustomAppTabLayout';

import '../../assets/scss/custom-tab-styles/style.scss';

class VoiceMessages extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            recordType: 'vmail/new',
            voiceMails: [],
            username: "Daniyal",
            loading: true,
            audioSource: '',
            showModal: false,
            direct: "0",
            showEngage: null,
        };
        this.playPauseRef = [];
    }

    customStyles = {
        content : {
            top                   : '50%',
            left                  : '50%',
            right                 : 'auto',
            bottom                : 'auto',
            marginRight           : '-50%',
            transform             : 'translate(-50%, -50%)'
        }
    };

    getNewMessages = (uType, userName, domain, password, cloudId) =>{
        this.infoOnExistingUser(uType, userName, domain, password, cloudId)
        const payLoad = new FormData();
        payLoad.append('user', userName.split("@")[0]);
        payLoad.append('domain', domain);
        payLoad.append('type', "vmail/new");
        payLoad.append('cloud_id', cloudId);

        this.setState({loading: true});
        axios.post(`${process.env.REACT_APP_API_NS_URL}?format=json&object=audio&action=read&utype=${uType}&cloud_username=${userName}&cloud_password=${password}`, payLoad)
        .then(response=>{
            this.createDataTable(response.data);
            this.setState({loading: false});
        })
        .catch(err=>{
            toast.error(err.message,);
            this.setState({loading: false})
        });

    }

    getSavedMessages = () =>{
        
        const payLoad = new FormData();
        payLoad.append('user', this.state.username.split("@")[0]);
        payLoad.append('domain', this.state.signinDomain);
        payLoad.append('type', "vmail/save");
        payLoad.append('cloud_id', this.state.cloudId);

        this.setState({loading: true});
        axios.post(`${process.env.REACT_APP_API_NS_URL}?format=json&object=audio&action=read&utype=${this.state.uType}&cloud_username=${this.state.username}&cloud_password=${this.state.password}`, payLoad)
        .then(response=>{
            this.createDataTable(response.data);
            this.setState({loading: false});
        })
        .catch(err=>{
            this.setState({loading: false});
            toast.error(err.message);
        });

    }

    getTrashedMessages = () =>{
        
        const payLoad = new FormData();
        payLoad.append('user', this.state.username.split("@")[0]);
        payLoad.append('domain', this.state.signinDomain);
        payLoad.append('type', "trash");
        payLoad.append('cloud_id', this.state.cloudId);

        this.setState({loading: true});
        axios.post(`${process.env.REACT_APP_API_NS_URL}?format=json&object=audio&action=read&utype=${this.state.uType}&cloud_username=${this.state.username}&cloud_password=${this.state.password}`, payLoad)
        .then(response=>{
            this.createDataTable(response.data);
            this.setState({loading: false});
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({loading: false});
        });

    }

    getDeviceInfo = (userName, domain, uType, password, cloudId) => {


        const payLoad = new FormData();
        payLoad.append('device', "sip:" + userName.split("@")[0] + "@" + userName.split("@")[1]);
        payLoad.append('domain', domain);
        payLoad.append('cloud_id', cloudId);
        
        axios.post(`${process.env.REACT_APP_API_NS_URL}?format=json&object=device&action=read&utype=${uType}&cloud_username=${userName}&cloud_password=${password}`, payLoad)
        .then(response=>{
            this.setState({username: (response.data[0].aor_user) +'@'+((response.data[0].sub_login).split("@")[1])});
            this.getNewMessages(uType, (response.data[0].aor_user) +'@'+((response.data[0].sub_login).split("@")[1]),domain, password, cloudId);

        })
        .catch(err=>{
            toast.error(err.message);
        });

    }

    checkIfShowEngage = (username, cloudId) => {
        const payLoad = {
            domain: username.split('@')[1],
            cloud_id: cloudId,
            extension: username.split('@')[0],
        } 

        axios.post(`${process.env.REACT_APP_API_URL}customWebTabCheck`, payLoad)
        .then(response=>{
            this.setState({showEngage: true});
        })
        .catch(err=>{
            console.log(err);
            this.setState({showEngage: false});
        });
    }

    componentDidMount(){

        let url = decodeURIComponent(window.location.href)

        url = url.substring(url.lastIndexOf("?username="),url.length);
        this.setState({url: url});

        let username = url.substring(url.lastIndexOf("?username=") + 10,url.lastIndexOf("&password="));
        let password = url.substring(url.lastIndexOf("&password=") + 10,url.lastIndexOf("&cloud_id="));
        let cloudId = url.substring(url.lastIndexOf("&cloud_id=") + 10,url.lastIndexOf("&direct="));
        let direct = url.substring(url.lastIndexOf("&direct=") + 8,url.length);
        cloudId = cloudId.replace("*", "")
        password = encodeURIComponent(password);
        this.setState({username: username});
        this.setState({password: password});
        this.setState({cloudId: cloudId});
        this.setState({direct: direct});
        this.checkIfShowEngage(username, cloudId)

        let uType = 's';
        if(username.includes("@QR")){
            // Here everything is based upon superuser
            this.getDeviceInfo(username, username.split("@")[1], uType, password, cloudId);
            this.setState({uType: uType});
            return
        }

        // Here we will get everything based upon simple user
        uType = 'u';
        const payLoad = {
            user: username,
            password: password,
            cloud_id: cloudId
        }

        axios.post(`${process.env.REACT_APP_API_NS_URL}/signin`, payLoad)
        .then(response=>{
            username =  username.split("@")[0] + "@" + response.data.domain;
            this.setState({username: username});
            this.setState({uid: response.data.uid});
            this.setState({signinDomain: response.data.domain});
            this.getNewMessages(uType, username, response.data.domain, password, cloudId);
            this.setState({uType: uType});

        })
        .catch(err=>{
            toast.error(err.message);
        });

    }
    toHHMMSS = (seconds) => {
        var sec_num = parseInt(seconds, 10); // don't forget the second param
        var hours   = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        var seconds = sec_num - (hours * 3600) - (minutes * 60);

        if (hours   < 10) {hours   = "0"+hours;}
        if (minutes < 10) {minutes = "0"+minutes;}
        if (seconds < 10) {seconds = "0"+seconds;}
        if(hours == "00"){
            return minutes+':'+seconds;
        }
        return hours+':'+minutes+':'+seconds;
    }
    formatNumber = (number) =>{
        if(number.length < 10){
            return number;
        }

        return "(" + (number.slice(-10)).slice(0,3)+") "+(number.slice(-10)).slice(3,6)+"-"+(number.slice(-10)).slice(6);
    }
    createDataTable = (dataFromApi) => {

        var jsonArray = [];
        for(var key in dataFromApi){
            const date = moment.utc(dataFromApi[key]["filedate"]).toDate();
            dataFromApi[key]["key"] = key;
            const searchRegExp = /amp;/g;
            dataFromApi[key]["remotepath"] = dataFromApi[key]["remotepath"].replace(searchRegExp,"");
            dataFromApi[key]["filedate"] = moment(date).local().format('MMM Do, h:mm a');
            dataFromApi[key]["NmsRecDuration"] = this.toHHMMSS(dataFromApi[key]["NmsRecDuration"]);
            dataFromApi[key]["DisplayNumber"] = this.formatNumber(dataFromApi[key]["NmsAni"]);
            jsonArray.push(dataFromApi[key]);
        }
        let voiceMails = jsonArray.map((row)=>{
                return (
                    <Auxi>
                    
                        <tr>
                            <td className="voice-mail-btn-td">
                                <div className="voice-mail-btn-div">

                                <a ref={(ref) => this.playPauseRef[(row.remotepath + "pause")] = ref}
                                    onClick={this.playHandler} data-id2={row.remotepath} 
                                    title="Play">
                                    <FontAwesomeIcon 
                                    icon={faPlayCircle} className="custom-data-table-icon"/>
                                    {/* <img className="img-icons" src={play}/> */}
                                </a>
                                
                                <a ref={(ref) => this.playPauseRef[(row.remotepath + "play")] = ref}
                                    onClick={this.pauseHandler} data-id2={row.remotepath} 
                                    title="Stop" style={{display:"none"}}>
                                    <FontAwesomeIcon 
                                    icon={faPauseCircle} className="custom-data-table-icon"/>
                                    {/* <img className="img-icons" src={pause}/> */}
                                </a>
                                
                                <a onClick={this.forwardHandler} data-id={row.key}
                                    title="Forward">
                                    <FontAwesomeIcon icon={faShare} className="custom-data-table-icon"/>
                                    {/* <img className="img-icons" src={forward}/> */}

                                </a>
                                
                                <a onClick={this.saveHandler} data-id={row.key}
                                    title="Save">
                                    <FontAwesomeIcon icon={faSave} className="custom-data-table-icon"/>
                                    {/* <img className="img-icons" src={save}/> */}
                                </a>
                                    
                                <a onClick={this.deleteHandler} data-id={row.key}
                                    title="Delete">
                                    <FontAwesomeIcon icon={faTrash} className="custom-data-table-icon"/>
                                    {/* <img className="img-icons" src={trash}/> */}
                                </a>
                                
                                
                                </div>

                            </td>
                        </tr>

                        <tr className="voice-mail-borderNone">
                            <td>
                                <div className="voice-mail-row2 voice-mail-displayInfo">
                                <span>{row.DisplayNumber}</span>
                                <span>
                                    {
                                    row.VmailForwarded ?

                                        <span className="fwd-label" title={"Forwarded from " +row.VmailForwarded}>
                                        Fwd
                                        </span>

                                    :
                                        null

                                    }
                                    {row.FromName}
                                </span>
                                </div>
                            </td>  

                        </tr>
                        <tr className="voice-mail-borderNone">
                            <td>
                                <div className="voice-mail-row2">
                                <span>{row.filedate}</span>
                                <span>{row.NmsRecDuration}</span>
                                </div>
                            </td>
                        </tr>
                        
                        {row.transcription ?
                            <tr>
                                <td className="voice-message-text" colspan="4">
                                    <span>{row.transcription}</span>
                                </td>
                            </tr>
                            : null
                        }

                    </Auxi>

                );
            });
            this.setState({voiceMails: voiceMails});
            this.setState({loading: false});
    }

    deleteHandler = (event) =>{

        const payLoad = new FormData();
        payLoad.append('owner', this.state.username.split("@")[0]);
        payLoad.append('owner_domain', this.state.signinDomain);
        payLoad.append('type', this.state.recordType);
        payLoad.append('index', event.currentTarget.getAttribute("data-id"));
        payLoad.append('cloud_id', this.state.cloudId);

        axios.post(`${process.env.REACT_APP_API_NS_URL}?format=json&object=audio&action=delete&utype=${this.state.uType}&cloud_username=${this.state.username}&cloud_password=${this.state.password}`, payLoad)
        .then(response=>{

            this.refreshData(this.state.recordType);

        })
        .catch(err=>{
            toast.error(err.message);
        });

    }

    saveHandler = (event) =>{
        
        const payLoad = new FormData();
        payLoad.append('owner', this.state.username.split("@")[0]);
        payLoad.append('owner_domain', this.state.signinDomain);
        payLoad.append('type', this.state.recordType);
        payLoad.append('index', event.currentTarget.getAttribute("data-id"));
        payLoad.append('cloud_id', this.state.cloudId);

        axios.post(`${process.env.REACT_APP_API_NS_URL}?format=json&object=audio&action=update&utype=${this.state.uType}&cloud_username=${this.state.username}&cloud_password=${this.state.password}`, payLoad)
        .then(response=>{

        this.refreshData(this.state.recordType);

        })
        .catch(err=>{
            toast.error(err.message);
        });

    }

    playHandler = (event) =>{
      //event.removeClass('feather');
        if(this.state.audioSource !== ''){
            this.playPauseRef[(this.state.audioSource + "play")].setAttribute("style", "display: none;");
            this.playPauseRef[(this.state.audioSource + "pause")].setAttribute("style", "display: bold;");
        }
        this.playPauseRef[(event.currentTarget.getAttribute('data-id2') + "pause")].setAttribute("style", "display: none;");
        this.playPauseRef[(event.currentTarget.getAttribute('data-id2') + "play")].setAttribute("style", "display: bold;");
        this.setState({audioSource: event.currentTarget.getAttribute('data-id2')})

    }

    pauseHandler = (event) =>{
        this.playPauseRef[(event.currentTarget.getAttribute('data-id2') + "play")].setAttribute("style", "display: none;");
        this.playPauseRef[(event.currentTarget.getAttribute('data-id2') + "pause")].setAttribute("style", "display: bold;");
        this.setState({audioSource: ''});
    }

    messageEnded = () => {
        if(this.state.audioSource != ''){
            this.playPauseRef[(this.state.audioSource + "play")].setAttribute("style", "display: none;");
            this.playPauseRef[(this.state.audioSource + "pause")].setAttribute("style", "display: bold;");
            this.setState({audioSource: ''});
        }
    }

    refreshData = (value) => {
        if(this.state.audioSource !== ''){
            this.playPauseRef[(this.state.audioSource + "play")].setAttribute("style", "display: none;");
            this.playPauseRef[(this.state.audioSource + "pause")].setAttribute("style", "display: bold;");
            this.setState({audioSource: ''});
            this.playPauseRef = [];
        }
        if(value === "vmail/save"){
            this.getSavedMessages();
        }
        else if (value === "vmail/new") {
            this.getNewMessages(this.state.uType, this.state.username, this.state.signinDomain, this.state.password, this.state.cloudId);
        }
        else{
            this.getTrashedMessages();
        }
    }

    selectChangeHandler = (event) =>{
        if(this.state.loading){
            toast.warn("Wait for loading to be finished");
            return;
        }
        this.refreshData(event.currentTarget.value);
        this.setState({recordType: event.currentTarget.value});

    }

    forwardHandler =(event) => {
        this.setState({forwardIndex: event.currentTarget.getAttribute("data-id")})
        this.setState({showModal:true});
    }

    onChangeHandler = (event) => {
        this.setState({[event.currentTarget.name]:event.currentTarget.value});
    }

    closeModal = (event) =>{
        this.setState({to_user:null});
        this.setState({showModal:false});
    }

    sendMessage = () => {
        if(!this.state.to_user || this.state.to_user === ''){
            return
        }

        const payLoad = new FormData();
            payLoad.append('owner', this.state.username.split("@")[0]);
            payLoad.append('owner_domain', this.state.signinDomain);
            payLoad.append('type', this.state.recordType);
            payLoad.append('index', this.state.forwardIndex);
            payLoad.append('forward', this.state.to_user);
            payLoad.append('cloud_id', this.state.cloudId);

        axios.post(`${process.env.REACT_APP_API_NS_URL}?format=json&object=audio&action=update&utype=${this.state.uType}&cloud_username=${this.state.username}&cloud_password=${this.state.password}`, payLoad)
        .then(response=>{

        this.setState({to_user:null});
        this.setState({showModal:false});
        toast.success("Forwarded Successfully!");

        })
        .catch(err=>{
        toast.error(err.message);
        });
    }

    downloadHandler = (event) =>{
        window.location.href = event.currentTarget.getAttribute("data-id");
    }
    fetchMessagesHandler = () => {
        window.location.reload();
    }

    infoOnExistingUser = (uType, userName, domain, password, cloudId) => {
        let payLoad = new FormData();
        payLoad.append('domain', domain);
        payLoad.append('user', userName.split("@")[0]);
        payLoad.append('fields', "callid_nmbr,user,domain,dir,email,first_name,last_name");
        payLoad.append('cloud_id', cloudId);
        
        const url = "?format=json&object=subscriber&action=read&utype=" + uType + "&cloud_password=" +password + "&cloud_username=" + userName;

        axios.post(process.env.REACT_APP_API_NS_URL + url, payLoad)
        .then(response=>{
            
            if(response.data[0]){
                this.setState({smsani: response.data[0].callid_nmbr})
                this.setState({ownContact: response.data[0]}) 
            }
            
        })
        .catch(err=>{
            toast.error(err.message);
        });
    }

    render() {

        let redirect = null;

        return(
            <Auxi>
                {
                    this.state.direct === "0" && this.state.showEngage !== null ?
                    <AppLayout cloud_id={this.state.cloudId}
                    display_name={this.state.username}
                    fetchMessages={this.fetchMessagesHandler}
                    ownContact={this.state.ownContact}
                    fromPage="voiceMail"
                    //showEngage={this.state.showEngage}
                    url={this.state.url} />
                    : null
                }
                {/*redirect*/}
								{/*<ReactAudioPlayer
									src={audioFile}
									controls
									showJumpControls=false
									showDownloadProgress=false


								/>*/}
                <Modal
                    modalIsOpen={this.state.showModal}
                    closeModal={this.closeModal}
                    onChangeHandler = {this.onChangeHandler}
                    sendMessage = {this.sendMessage}
                    toUser = {this.state.to_user}
                />

                <AudioPlayer
                    autoPlay
                    preload={"none"}
                    src={this.state.audioSource}
                    onEnded={e => this.messageEnded()}
                />

                <div className = "CustomMainIndexApp">
                    <div className="row voice-message-layout">
                        <div className="col-xs-0 col-md-2"> </div>
                        <div className = "col-xs-12 col-md-8">
                            <div className="page-header customTitleMiddle">
                                <h3 className="m-b-10">
                                    {this.state.loading
                                    ? <Spinner data="" />
                                    : null
                                    }
                                </h3>
                            </div>
                            <div className="row form-group">
                                <div className="col-xs-4">
                                    <select className="form-control"
                                        onChange={this.selectChangeHandler}
                                        value={this.state.recordType} name="exporter_type">

                                        <option value="vmail/new"> New </option>
                                        <option value="vmail/save"> Saved </option>
                                        <option value="trash"> Trash </option>

                                    </select>
                                </div>
                            </div>
                            <div className="voice-messages-table">
                                { (this.state.voiceMails).length ?
                                    <table className="table">
                                        <tbody>
                                            {this.state.voiceMails} 
                                        </tbody>
                                    </table>
                                    :
                                    <div className="nonecreated">
                                        <h3>You have no {(this.state.recordType).split("/")[1]} messages.</h3>
                                    </div>
                                }

                            </div>
                        </div>
                        <div className="col-xs-0 col-md-2"></div>
                    </div>
                </div>
            </Auxi>

        );

    }

}

export default VoiceMessages;
