import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import '../../assets/css/style.css';

const FilterModal = (props) => {

    return(
        <>

            <Modal isOpen={props.show} toggle={props.toggleHandler}>
                <ModalHeader toggle={props.toggleHandler}>{"Filter Call Parks"}</ModalHeader>
                <ModalBody>
                    <form>

                        <div className="row form-group">
                            {
                                props.callParkingsData.map((row, index)=>{
                                    if(!((Number(row.queue_name) >= props.parkingLowerLimt && Number(row.queue_name) <= props.parkingUpperLimt) || (Number(row.queue_name) >= props.parkingLowerLimt2 && Number(row.queue_name) <= props.parkingUpperLimt2))){
                                        return null;
                                    } 
                                    return (
                                        <div className="col-6">
                                            
                                            <input  id={"cpCb"+index} type="checkbox" 
                                                className="cp-modal-check" onChange={props.onChangeHandler} 
                                                name={row.queue_name} value={row.queue_name}
                                                checked={props.filteredList.includes(row.queue_name)}/>
                                            <label for={"cpCb"+index}  className="cp-modal-label">  
                                                {row.description}
                                            </label><br></br>
                                        </div>
                                    )
                                    
                                })
                            }
                            
                        </div>
                        
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={props.toggleHandler}>{"Cancel"}</Button>
                </ModalFooter>
            </Modal>
        </>

    )};

export default FilterModal;
