import { toast } from 'react-toastify';
// import { css } from 'glamor';

const customToast = {
  success(msg, options = {}) {
    return toast.success(msg, {
      ...options,
      className: 'toast-success-container toast-success-container-after',
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  },
  error(msg, options = {}) {
    return toast.error(msg, {
      ...options,
      className: 'toast-error-container toast-error-container-after',
      // progressClassName: css({
      //   background: '#EE0022',
      // }),
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  },
  info(msg, options = {}) {
    return toast.info(msg, {
      ...options,
      className: 'toast-info-container toast-info-container-after',
      // progressClassName: css({
      //   background: '#07F',
      // }),
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  },
  warn(msg, options = {}) {
    return toast.warn(msg, {
      ...options,
      className: 'toast-warn-container toast-warn-container-after',
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  },
  dark(msg, options = {}) {
    return toast.dark(msg, {
      ...options,
      className: 'toast-dark-container toast-dark-container-after',
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  },
  default(msg, options = {}) {
    return toast(msg, {
      ...options,
      className: 'toast-default-container toast-default-container-after',
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  },
};

export default customToast;
