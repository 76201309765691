import React, { Fragment } from 'react';
import { toast } from 'react-toastify';
import { Card, CardBody, CardHeader } from 'reactstrap';
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios';
import { Redirect } from 'react-router';

import ImageUploader from 'react-images-upload';

import '../../assets/scss/app-styles/users.scss'
import { ThisWeekTask } from '../../constant';

class WhiteLabel extends React.Component {

    constructor(props) {
        super(props);
        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.saveHandler = this.saveHandler.bind(this);
        this.cancelHandler = this.cancelHandler.bind(this);
    }

    state = {
        data: {
        },
        backToIndex: false,
        style: 'custom-spinner',
    };
    headers = {
        headers: {
            Token: localStorage.getItem("token"),
        }
    }


    inputChangeHandler(event) {
        var data = this.state.data;
        data[event.target.name] = event.target.value;
        this.setState({ data });

    }
    populateItems = (data) => {

        let items = [];
        items.push(<option value={""}></option>);
        data.map((row) => (
            items.push(<option value={row}>{row}</option>)
        ));

        return items;

    }

    populateDefaults() {
        axios.get(`${process.env.REACT_APP_API_URL}getDefaults`, this.headers)
            .then(response => {

                let items = [];

                items = this.populateItems(response.data.email_encryption.Allowed);
                this.setState({ emailEncryptionItems: items })

            })
            .catch(err => {
                toast.error(err.message);

            }
            );
    }

    componentDidMount() {
        if (!this.props.location.state || JSON.parse(localStorage.getItem('creds')).cloud_id !== "SNAPCOM") {
            this.setState({ backToIndex: true });
            this.setState({ style: 'custom-spinner-hide' });
            return;
        }
        this.populateDefaults();
        this.setState({ style: 'custom-spinner' });

        const payLoad = {
            subscriber_id: this.props.location.state.subscriberId,
            user_id: JSON.parse(localStorage.getItem('creds')).id
        };
        axios.post(`${process.env.REACT_APP_API_URL}whiteLabelDetails`, payLoad, this.headers)
            .then(response => {
                var data = this.state.data;
                data = response.data;
                this.setState({ data });
                this.setState({ style: 'custom-spinner-hide' });
            }).catch(err => {
                //toast.error(err.response.data);
                this.setState({ style: 'custom-spinner-hide' });
            });

    }

    saveHandler(event) {
        event.preventDefault();
        if (!this.state.data.admin_base_url || !this.state.data.email_host ||
            !this.state.data.email_port || !this.state.data.email_username || !this.state.data.email_password ||
            !this.state.data.email_from || !this.state.data.email_encryption || !this.state.data.park_lower_limit
            || !this.state.data.park_upper_limit || !this.state.data.netsapien_base_url
            || !this.state.data.signin_btn_color || !this.state.data.signin_branding
            || !this.state.data.forgot_name_url || !this.state.data.forgot_password_url) {
            toast.error('All fields are mandatory!');
            return;
        }

        if (!this.state.data.id && (!this.state.signin_logo || !this.state.dashboard_logo ||
            !this.state.custom_tab_background)) {

            toast.error('All Logos/images are mandatory!');
            return;
        }

        this.setState({ style: 'custom-spinner' });
        var data = this.state.data;
        this.setState({ data });
        let payLoad = new FormData();
        payLoad.append('admin_base_url', data.admin_base_url);
        payLoad.append('frontend_base_url', data.admin_base_url);
        payLoad.append('email_host', data.email_host);
        payLoad.append('email_port', data.email_port);
        payLoad.append('email_username', data.email_username);
        payLoad.append('email_password', data.email_password);
        payLoad.append('email_from', data.email_from);
        payLoad.append('email_encryption', data.email_encryption);
        payLoad.append('park_lower_limit', data.park_lower_limit);
        payLoad.append('park_upper_limit', data.park_upper_limit);
        payLoad.append('netsapien_base_url', data.netsapien_base_url);
        payLoad.append('signin_btn_color', data.signin_btn_color);
        payLoad.append('signin_branding', data.signin_branding);
        payLoad.append('forgot_name_url', data.forgot_name_url);
        payLoad.append('forgot_password_url', data.forgot_password_url);

        if (this.state.signin_logo) {
            payLoad.append('signin_logo', this.state.signin_logo);
        }
        if (this.state.dashboard_logo) {
            payLoad.append('dashboard_logo', this.state.dashboard_logo);
        }
        if (this.state.custom_tab_background) {
            payLoad.append('custom_tab_background', this.state.custom_tab_background);
        }

        payLoad.append('user_id', JSON.parse(localStorage.getItem('creds')).id);
        payLoad.append('subscriber_id', this.props.location.state.subscriberId);

        axios.post(`${process.env.REACT_APP_API_URL}createUpdateWhiteLabel`, payLoad, this.headers)
            .then(response => {
                this.setState({ backToSummary: true });
                this.setState({ style: 'custom-spinner-hide' });
            }).catch(err => {
                if (err.response) {
                    toast.error(err.response.data);
                } else {
                    toast.error(err.message);
                }
                this.setState({ style: 'custom-spinner-hide' });
            });
    }

    cancelHandler() {
        this.setState({ backToSummary: true });
    }

    onDropSignin = (pictureFiles, pictureDataURLs) => {
        this.setState({ signin_logo: pictureFiles[0] });
    }
    onDropDashboard = (pictureFiles, pictureDataURLs) => {
        this.setState({ dashboard_logo: pictureFiles[0] });
    }
    onDropCustomTab = (pictureFiles, pictureDataURLs) => {
        this.setState({ custom_tab_background: pictureFiles[0] });
    }

    render() {

        let redirect = null;
        if (this.state.backToSummary) {
            this.setState({ backToSummary: false });
            redirect = <Redirect to={{
                pathname: '/subscribers',
            }} />;
        }
        if (this.state.backToIndex) {
            redirect = <Redirect to={{
                pathname: "/dashboard"
            }} />;
        }

        return (
            <Fragment>
                {redirect}
                <Breadcrumb parent="Subscribers" title="White Label" />
                <div className={"loader-box " + this.state.style} >
                    <div className="loader">
                        <div className="line bg-primary"></div>
                        <div className="line bg-primary"></div>
                        <div className="line bg-primary"></div>
                        <div className="line bg-primary"></div>
                    </div>
                </div>
                <div className="container-fluid main-container">
                    <div className="col-md-2 col-sm-0"></div>
                    <Card className="col-md-8 col-sm-12">
                        <CardHeader>
                            <h5>{this.state.data.id ? "White Label Edit" : "White Label Add"}</h5>
                        </CardHeader>
                        <CardBody className="dropdown-basic">
                            <div className="row form-group">
                                <div className="col-md-12">
                                    <label className="float-left">
                                        Admin Portal URL
                                    </label> <span> * </span>
                                    <input type="text" className="form-control" name="admin_base_url"
                                        onChange={this.inputChangeHandler} value={this.state.data.admin_base_url} />
                                </div>
                                {/* <div className="col-md-6">
                                    <label className="float-left">
                                        Frontend URL
                                    </label> <span> * </span>
                                    <input type="text" className="form-control" name="frontend_base_url"
                                        onChange={this.inputChangeHandler} value={this.state.data.frontend_base_url} />
                                </div> */}
                            </div>

                            <div className="row form-group">
                                <div className="col-md-6">
                                    <label className="float-left">
                                        SMTP Email Host
                                    </label> <span> * </span>
                                    <input type="text" className="form-control" name="email_host"
                                        onChange={this.inputChangeHandler} value={this.state.data.email_host} />
                                </div>
                                <div className="col-md-6">
                                    <label className="float-left">
                                        SMTP Email Port
                                    </label> <span> * </span>
                                    <input type="text" className="form-control" name="email_port"
                                        onChange={this.inputChangeHandler} value={this.state.data.email_port} />
                                </div>

                            </div>

                            <div className="row form-group">
                                <div className="col-md-6">
                                    <label className="float-left">
                                        Email Username
                                    </label> <span> * </span>
                                    <input type="text" className="form-control" name="email_username"
                                        onChange={this.inputChangeHandler} value={this.state.data.email_username} />
                                </div>

                                <div className="col-md-6 custom-radio">
                                    <label className="float-left">
                                        Email Password
                                    </label> <span> * </span>
                                    <input type="password" className="form-control" name="email_password"
                                        onChange={this.inputChangeHandler} value={this.state.data.email_password} />
                                </div>
                            </div>

                            <div className="row form-group">
                                <div className="col-md-6">
                                    <label className="float-left">
                                        Email From Address
                                    </label> <span> * </span>
                                    <input type="text" className="form-control" name="email_from"
                                        onChange={this.inputChangeHandler} value={this.state.data.email_from} />
                                </div>
                                <div className="col-md-6">
                                    <label className="float-left">
                                        Email Encryption
                                    </label> <span> * </span>
                                    <select className="form-control"
                                        onChange={this.inputChangeHandler} name="email_encryption"
                                        value={this.state.data.email_encryption}>
                                        {this.state.emailEncryptionItems}
                                    </select>
                                </div>
                            </div>

                            <div className="row form-group">
                                <div className="col-md-6">
                                    <label className="float-left">
                                        Call Parking Lower Limit
                                    </label> <span> * </span>
                                    <input type="number" className="form-control" name="park_lower_limit"
                                        onChange={this.inputChangeHandler} value={this.state.data.park_lower_limit} />
                                </div>
                                <div className="col-md-6">
                                    <label className="float-left">
                                        Call Parking Upper Limit
                                    </label> <span> * </span>
                                    <input type="number" className="form-control" name="park_upper_limit"
                                        onChange={this.inputChangeHandler} value={this.state.data.park_upper_limit} />
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col-md-12">
                                    <label className="float-left">
                                    Dynamic Core URL (Region.domain/ns-api - region is dynamically pulled from NS UI)
                                    </label> <span> * </span>
                                    <input type="text" className="form-control" name="netsapien_base_url"
                                        onChange={this.inputChangeHandler} value={this.state.data.netsapien_base_url} />
                                </div>
                            </div>

                            <div className="row form-group">
                                <div className="col-md-6">
                                    <label className="float-left">
                                        Signin Button Color
                                    </label> <span> * </span>
                                    <input type="text" className="form-control" name="signin_btn_color"
                                        onChange={this.inputChangeHandler} value={this.state.data.signin_btn_color} />
                                </div>
                                <div className="col-md-6">
                                    <label className="float-left">
                                        Signin Branding
                                    </label> <span> * </span>
                                    <input type="text" className="form-control" name="signin_branding"
                                        onChange={this.inputChangeHandler} value={this.state.data.signin_branding} />
                                </div>
                            </div>

                            <div className="row form-group">
                                <div className="col-md-6">
                                    <label className="float-left">
                                        Signin Forgot Name URL
                                    </label> <span> * </span>
                                    <input type="text" className="form-control" name="forgot_name_url"
                                        onChange={this.inputChangeHandler} value={this.state.data.forgot_name_url} />
                                </div>
                                <div className="col-md-6">
                                    <label className="float-left">
                                        Signin Forgot Password URL
                                    </label> <span> * </span>
                                    <input type="text" className="form-control" name="forgot_password_url"
                                        onChange={this.inputChangeHandler} value={this.state.data.forgot_password_url} />
                                </div>
                            </div>

                            <div className="row form-group">
                                <label className="col-md-12">
                                    Signin Logo*
                                </label>
                                <div className="col-md-6">
                                    {
                                        this.state.data.signin_logo ?
                                            <img className="align-self-center pull-right img-100 blur-up lazyloaded"
                                                src={process.env.REACT_APP_API_URL + this.state.data.signin_logo}
                                                alt="header-user" style={{ background: "black" }} />
                                            : null
                                    }
                                </div>
                                <div className="col-md-6">

                                    <ImageUploader
                                        withIcon={false}
                                        withPreview={true}
                                        label=""
                                        singleImage={true}
                                        buttonText="Upload"
                                        onChange={this.onDropSignin}
                                        imgExtension={[".jpg", ".jpeg", ".gif", ".png", ".gif", ".svg"]}
                                        maxFileSize={(5 * 1048576)} // no. of mbs you want * MB value (mb value is static dont change)
                                        fileSizeError=" file size is too big"
                                    />
                                </div>

                            </div>


                            <div className="row form-group">
                                <label className="col-md-12">
                                    Dashboard Logo*
                                </label>
                                <div className="col-md-6">
                                    {
                                        this.state.data.dashboard_logo ?
                                            <img className="align-self-center pull-right img-100 blur-up lazyloaded"
                                                src={process.env.REACT_APP_API_URL + this.state.data.dashboard_logo}
                                                alt="header-user" style={{ background: "black" }} />
                                            : null
                                    }
                                </div>
                                <div className="col-md-6">

                                    <ImageUploader
                                        withIcon={false}
                                        withPreview={true}
                                        label=""
                                        singleImage={true}
                                        buttonText="Upload"
                                        onChange={this.onDropDashboard}
                                        imgExtension={[".jpg", ".jpeg", ".gif", ".png", ".gif", ".svg"]}
                                        maxFileSize={(5 * 1048576)} // no. of mbs you want * MB value (mb value is static dont change)
                                        fileSizeError=" file size is too big"
                                    />
                                </div>

                            </div>


                            <div className="row form-group">
                                <label className="col-md-12">
                                    Custom Tab Background*
                                </label>
                                <div className="col-md-6">
                                    {
                                        this.state.data.custom_tab_background ?
                                            <img className="align-self-center pull-right img-100 blur-up lazyloaded"
                                                src={process.env.REACT_APP_API_URL + this.state.data.custom_tab_background}
                                                alt="header-user" style={{ background: "black" }} />
                                            : null
                                    }
                                </div>
                                <div className="col-md-6">

                                    <ImageUploader
                                        withIcon={false}
                                        withPreview={true}
                                        label=""
                                        singleImage={true}
                                        buttonText="Upload"
                                        onChange={this.onDropCustomTab}
                                        imgExtension={[".jpg", ".jpeg", ".gif", ".png", ".gif", ".svg"]}
                                        maxFileSize={(5 * 1048576)} // no. of mbs you want * MB value (mb value is static dont change)
                                        fileSizeError=" file size is too big"
                                    />
                                </div>

                            </div>

                            <div className="row form-group">
                                <div className="col-md-12">

                                    <button type="button" onClick={this.cancelHandler} className="button-custom btn shadow-2 btn-secondary btn-width">
                                        CANCEL
                                    </button>
                                    <button type="button" onClick={this.saveHandler} className="button-custom btn shadow-2 btn-success btn-width">
                                        SAVE
                                    </button>

                                </div>
                            </div>
                        </CardBody>
                    </Card>
                    <div className="col-md-2 col-sm-0"></div>
                </div>
            </Fragment>
        );

    }
}

export default WhiteLabel;
