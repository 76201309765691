import React, { Fragment } from 'react';
import { toast } from 'react-toastify';
import {Card,CardBody,CardHeader } from 'reactstrap';
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios';
import {Redirect} from 'react-router';

import '../../assets/scss/app-styles/users.scss'

class Details extends React.Component {

	constructor(props) {
		super(props);
		this.inputChangeHandler = this.inputChangeHandler.bind(this);
		this.saveHandler = this.saveHandler.bind(this);
		this.cancelHandler = this.cancelHandler.bind(this);
	}

	state = {
		data: {
            transport: "udp",
            ringingtone: "periodic(sine(2000ms,2500,440Hz),silence(4000ms))",
            pushnat: "0",
		},
		backToIndex: false,
        style: 'custom-spinner',
	};
	headers = {
        headers: {
            Token: localStorage.getItem("token"),
        }
    }

    transportItems = [];

    populateItems = (data) => {
        
        let items = [];
        items.push(<option value={""}></option>);
        data.map((row)=>(
            items.push(<option value={row}>{row}</option>)
        ));

        return items;

    }

    populateDefaults(){
        axios.get(`${process.env.REACT_APP_API_URL}getDefaults`, this.headers)
            .then(response=>{

                let items = [];
                    
                    items = this.populateItems(response.data.transport.Allowed);
                    this.transportItems = items;
                    
                    items = this.populateItems(response.data.outboundproxytransport.Allowed);
                    this.outboundProxyTransportItems = items;
                    
                    items = this.populateItems(response.data.codecorder.Allowed);
                    this.codecOrderItems = items;
                    
                    items = this.populateItems(response.data.codecorder3g.Allowed);
                    this.codecOrder3gItems = items;
                    
                    items = this.populateItems(response.data.codecorderwebrtc.Allowed);
                    this.codecorderwebrtcItems = items;
                    
                    items = this.populateItems(response.data.dtmforder.Allowed);
                    this.dtmforderItems = items;
                    
                    items = this.populateItems(response.data.videocodecorder.Allowed);
                    this.videocodecorderItems = items;
                    
                    items = this.populateItems(response.data.videocodecorder3g.Allowed);
                    this.videocodecorder3gItems = items;
                    
                    items = this.populateItems(response.data.videocodecorderwebrtc.Allowed);
                    this.videocodecorderwebrtcItems = items;

                    this.setState({loading: false});

            })
            .catch(err=>{
                toast.error(err.message);

            }
        );
    }

	inputChangeHandler(event) {
		var data = this.state.data;
		data[event.target.name] = event.target.value;
		

        if(event.target.name === "subscriber_id"){
            const index = event.target.selectedIndex;
            data["cloud_id"] = event.target[index].text;
        }
        this.setState({ data });

	}

    selectChangeDataHandler =(event) => {
        const data = this.state.data;
        data[event.target.name] = event.target.value;
        this.setState({data});
    }
    selectChangeHandler  = (event) => {

        this.setState({[event.target.name]: event.target.value});

    }

    pushHandler = (event) =>{
            
        if(!this.state[event.target.getAttribute("id")] || this.state[event.target.getAttribute("id")] == ""){
            return;
        }
        let data = this.state.data;
        if(!data[event.target.getAttribute("id")] || data[event.target.getAttribute("id")] == ""){
            data[event.target.getAttribute("id")] = this.state[event.target.getAttribute("id")];
        }
        else{
            if((data[event.target.getAttribute("id")].split(","))
                .filter(element => element == this.state[event.target.getAttribute("id")]? element : null).length == 0){
                
                data[event.target.getAttribute("id")] += "," + this.state[event.target.getAttribute("id")];
            }
        }
        this.setState({data});
    }

    popHandler = (event) => {

        let data = this.state.data;
        if(!data[event.target.getAttribute("id")] || data[event.target.getAttribute("id")] == ""){
            return;
        }

        data[event.target.getAttribute("id")] = data[event.target.getAttribute("id")].substr(0, (data[event.target.getAttribute("id")].lastIndexOf(",")));
        this.setState({data});

    }

    getSubscribers = (evt) => {
        const payLoad = {
            user_id: JSON.parse(localStorage.getItem('creds')).id
        }

        axios.post(`${process.env.REACT_APP_API_URL}getCloudIds`, payLoad, this.headers)
        .then(response => {
            let items = [];
            items.push(<option value={""}></option>);
            response.data.map((row)=>(
                items.push(<option value={row.id}>{row.cloud_id}</option>)
            ));
            this.subscribersItems = items;
            this.setState({subscribersItems: items})
        }).catch(err => {
            toast.error(err.response.data);
            this.setState({style: 'custom-spinner-hide'});
        });
    }


	componentDidMount() {
        if(JSON.parse(localStorage.getItem('creds')).cloud_id !== "SNAPCOM"){
			this.setState({backToIndex: true});
            this.setState({style: 'custom-spinner-hide'});
			return;
		}
        this.populateDefaults();
        this.getSubscribers();
		if (this.props.location.state.id) {
			this.setState({style: 'custom-spinner'});
			const payLoad = {
				id: this.props.location.state.id,
				user_id: JSON.parse(localStorage.getItem('creds')).id
			};
			axios.post(`${process.env.REACT_APP_API_URL}profileDetail`, payLoad, this.headers)
			.then(response => {
				var data = this.state.data;
				data = response.data;
				this.setState({ data });
				this.setState({style: 'custom-spinner-hide'});
			}).catch(err => {
				toast.error(err.response.data);
                this.setState({style: 'custom-spinner-hide'});
			});
		} else {

			this.setState({style: 'custom-spinner-hide'});
		}

	}

	saveHandler(event) {
		event.preventDefault();
		if (!this.state.data.subscriber_id ||
                !this.state.data.proxy || !this.state.data.transport) {
            toast.error('Fields with * are mandatory!');
            return;
		}
        if(this.state.data.expires && this.state.data.expires.length > 3){
            toast.error("Expires value can not be greater than 999");
            return;
        }
		this.setState({style: 'custom-spinner'});
		var data = this.state.data;
		this.setState({ data });
		const payLoad = this.state.data;
		axios.post(`${process.env.REACT_APP_API_URL}profileCreateUpdate`, payLoad, this.headers)
		.then(response => {
			this.setState({ backToSummary: true });
            this.setState({style: 'custom-spinner-hide'});
		}).catch(err => {
            toast.error(err.response.data);
            this.setState({style: 'custom-spinner-hide'});
		});
	}

	cancelHandler() {
		this.setState({ backToSummary: true });
	}

	render() {

		let redirect = null;
		if (this.state.backToSummary) {
			this.setState({ backToSummary: false });
			redirect = <Redirect to={{
				pathname: '/client_profiles',
			}}/>;
		}
		if(this.state.backToIndex){
			redirect = <Redirect to = {{
				pathname: "/dashboard"
			}} />;
		}

		return (
            <Fragment>
                {redirect}
                <Breadcrumb parent="Client Profiles" title="Details" parent_url="client_profiles" />
                <div className={"loader-box " + this.state.style} >
                    <div className="loader">
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    </div>
                </div>
                <div className="container-fluid main-container">
                    <div className="col-md-2 col-sm-0"></div>
                    <Card className="col-md-8 col-sm-12">
                        <CardHeader>
                            <h5>{this.props.location.state && this.props.location.state.id? "Edit Client Profile" : "Add Client Profile"}</h5>
                        </CardHeader>
                        <CardBody className="dropdown-basic">
                            <div className="row form-group">
                                <div className="col-md-6">
                                    <label className="float-left"> Proxy </label> <span> * </span>
                                    <input type="text" className="form-control" name="proxy"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.proxy}/>
                                </div>

                                <div className="col-md-6">
                                    <label className="float-left"> 
                                        Transport  
                                    </label> <span> * </span>
                                    <select  className="form-control"
                                        onChange={this.inputChangeHandler} name="transport" 
                                        value={this.state.data.transport}>
                                        {this.transportItems}
                                    </select>
                                </div>

                                
                            </div>

                            <div className="row form-group">

                                {
                                    this.state.subscribersItems? 
                                        <div className="col-md-6">
                                            <label> Subscriber  </label> <span> * </span>
                                            <select  className="form-control"
                                                onChange={this.inputChangeHandler} name="subscriber_id" 
                                                value={this.state.data.subscriber_id}>
                                                {this.state.subscribersItems}
                                            </select>
                                        </div>
                                    : null
                                }
                                
                                <div className="col-md-6">
                                    <label className="float-left">
                                        Expires
                                    </label>
                                    <input type="number" className="form-control" name="expires"
                                        onChange={this.inputChangeHandler} value={this.state.data.expires}/>
                                </div>
                                
                            </div>

                            <div className="row form-group">

                                <div className="col-md-6 custom-radio">
                                    <label> 
                                        Out Bound Proxy Enabled  
                                    </label>
                                    <div onChange={this.inputChangeHandler}>
                                        <input className="margin-left-10"
                                            type="radio" value="1" name="outboundproxyenabled"
                                            checked={this.state.data.outboundproxyenabled === "1"}
                                            /> Yes
                                        <input className="margin-left-10"
                                            type="radio" value="0" name="outboundproxyenabled"
                                            checked={this.state.data.outboundproxyenabled === "0"}/> No
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <label className="float-left">
                                        Outbound Proxy Host
                                    </label>
                                    <input type="text" className="form-control" name="outboundproxyhost"
                                        onChange={this.inputChangeHandler} value={this.state.data.outboundproxyhost}/>
                                </div>
                                
                            </div>

                            <div className="row form-group">

                                <div className="col-md-6">
                                    <label className="float-left">Out Bound Proxy Transport </label>
                                    <select  className="form-control"
                                        onChange={this.inputChangeHandler} name="outboundproxytransport" 
                                        value={this.state.data.outboundproxytransport}>
                                        {this.outboundProxyTransportItems}
                                    </select>
                                    {/* <input type="text" className="form-control" name="outboundproxytransport"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.outboundproxytransport}/> */}
                                </div>

                                <div className="col-md-6">
                                    <label className="float-left"> Email  </label>
                                    <input type="text" className="form-control" name="email"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.email}/>
                                </div>

                            </div>
                            <div className="row form-group">

                                <div className="col-md-12">
                                    <label className="float-left"> RingingTone  </label>
                                    <input type="text" className="form-control" name="ringingtone"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.ringingtone}/>
                                </div>

                            </div>

                            <div className="row form-group">

                                <div className="col-md-6 custom-radio">
                                    <label> Push NAT  </label>
                                    <div onChange={this.inputChangeHandler}>
                                        <input className="margin-left-10"
                                            type="radio" value="1" name="pushnat"
                                            checked={this.state.data.pushnat === "1"}
                                            /> 1
                                        <input className="margin-left-10"
                                            type="radio" value="0" name="pushnat"
                                            checked={this.state.data.pushnat === "0"}/> 0
                                    </div>
                                </div>

                            </div>


                            <div className="row form-group">
                                <div className="col-md-6 custom-radio">
                                    <label> Allow Message  </label>
                                    <div onChange={this.inputChangeHandler}>
                                    <input className="margin-left-10"
                                        type="radio" value="1" name="allowmessage"
                                        checked={this.state.data.allowmessage === "1"}
                                        /> Yes
                                    <input className="margin-left-10"
                                        type="radio" value="0" name="allowmessage"
                                        checked={this.state.data.allowmessage === "0"}/> No
                                    </div>
                                </div>
                                <div className="col-md-6 custom-radio">
                                    <label> Allow Video  </label>
                                    <div onChange={this.inputChangeHandler}>
                                    <input className="margin-left-10"
                                        type="radio" value="1" name="allowvideo"
                                        checked={this.state.data.allowvideo === "1"}/> Yes
                                    <input className="margin-left-10"
                                        type="radio" value="0" name="allowvideo"
                                        checked={this.state.data.allowvideo === "0"}/> No
                                    </div>
                                </div>
                            </div>


                            <div className="row form-group">

                                <div className="col-md-6 custom-radio">
                                    <label> Auto Answer Enabled  </label>
                                    <div onChange={this.inputChangeHandler}>
                                        <input className="margin-left-10"
                                        type="radio" value="1" name="autoanswerenabled"
                                        checked={this.state.data.autoanswerenabled === "1"}/> Yes
                                        <input className="margin-left-10"
                                        type="radio" value="0" name="autoanswerenabled"
                                        checked={this.state.data.autoanswerenabled === "0"}/> No
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label className="float-left"> User Caller Id </label>
                                    <input type="text" className="form-control" name="usercallerid"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.usercallerid}/>
                                </div>

                            </div>


                            <div className="row form-group">
                                <div className="col-md-6">
                                    <label className="float-left"> Auto Recieve Video 3g  </label>
                                    <input type="text" className="form-control" name="autoreceivevideo3g"
                                        onChange={this.inputChangeHandler}
                                        value = {this.state.data.autoreceivevideo3g}/>
                                </div>
                                <div className="col-md-6">
                                    <label className="float-left"> Auto Receive Video Wifi  </label>
                                    <input type="text" className="form-control" name="autoreceivevideowifi"
                                        onChange={this.inputChangeHandler}
                                        value = {this.state.data.autoreceivevideowifi}/>
                                </div>
                            </div>


                            <div className="row form-group">
                                <div className="col-md-6">
                                    <label className="float-left"> Auto Send Video 3g  </label>
                                    <input type="text" className="form-control" name="autosendvideo3g"
                                        onChange={this.inputChangeHandler}
                                        value = {this.state.data.autosendvideo3g}/>
                                </div>
                                <div className="col-md-6">
                                    <label className="float-left"> Auto Send Video Wifi  </label>
                                    <input type="text" className="form-control" name="autosendvideowifi"
                                        onChange={this.inputChangeHandler}
                                        value = {this.state.data.autosendvideowifi}/>
                                </div>
                            </div>

                            <div className="row form-group order-component">

                                <div className="col-md-6">
                                    <label className="float-left"> Codec Order  </label>
                                    <input type="text" className="form-control" name="codecorder"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.codecorder} disabled/>
                                </div>

                                <div className="col-md-custom">
                                    <select  className="form-control"
                                        onChange={this.selectChangeHandler} name="codecorder">
                                        {this.codecOrderItems}
                                    </select>

                                </div>
                                <div className="col-md-2">
                                    
                                    <button  type="button" onClick={this.pushHandler} id="codecorder"
                                        className="button-custom btn btn-primary btn-width">
                                        Add
                                    </button>

                                </div>

                                <div className="col-md-2">
                                    
                                    <button  type="button" onClick={this.popHandler} id="codecorder"
                                        className="button-custom btn btn-secondary btn-width ml-3">
                                        Remove
                                    </button>

                                </div>

                            </div>

                            <div className="row form-group order-component">

                                <div className="col-md-6">
                                    <label className="float-left"> Codec Order 3G  </label>
                                    <input type="text" className="form-control" name="codecorder3g"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.codecorder3g} disabled/>
                                </div>

                                <div className="col-md-custom">
                                    <select  className="form-control"
                                        onChange={this.selectChangeHandler} name="codecorder3g">
                                        {this.codecOrder3gItems}
                                    </select>

                                </div>
                                <div className="col-md-2">
                                    
                                    <button  type="button" onClick={this.pushHandler} id="codecorder3g"
                                        className="button-custom btn btn-primary btn-width">
                                        Add
                                    </button>

                                </div>

                                <div className="col-md-2">
                                    
                                    <button  type="button" onClick={this.popHandler} id="codecorder3g"
                                        className="button-custom btn btn-secondary btn-width ml-3">
                                        Remove
                                    </button>

                                </div>

                            </div>

                            <div className="row form-group order-component">

                                <div className="col-md-6">
                                    <label className="float-left"> Codec Order Web RTC  </label>
                                    <input type="text" className="form-control" name="codecorderwebrtc"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.codecorderwebrtc} disabled/>
                                </div>

                                <div className="col-md-custom">
                                    <select  className="form-control"
                                        onChange={this.selectChangeHandler} name="codecorderwebrtc">
                                        {this.codecorderwebrtcItems}
                                    </select>

                                </div>
                                <div className="col-md-2">
                                    
                                    <button  type="button" onClick={this.pushHandler} id="codecorderwebrtc"
                                        className="button-custom btn btn-primary btn-width">
                                        Add
                                    </button>

                                </div>

                                <div className="col-md-2">
                                    
                                    <button  type="button" onClick={this.popHandler} id="codecorderwebrtc"
                                        className="button-custom btn btn-secondary btn-width ml-3">
                                        Remove
                                    </button>

                                </div>

                            </div>

                            {/* <div className="row form-group">
                                <div className="col-md-6">
                                    <label className="float-left"> Codecorder 3g  </label>
                                    <input type="text" className="form-control" name="codecorder3g"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.codecorder3g}/>
                                </div>
                                <div className="col-md-6">
                                    <label className="float-left"> Codecorder webrtc  </label>
                                    <input type="text" className="form-control" name="codecorderwebrtc"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.codecorderwebrtc}/>
                                </div>
                            </div> */}

                            <div className="row form-group">
                                <div className="col-md-6">
                                    <label className="float-left"> Conferencing Enabled  </label>
                                    <input type="text" className="form-control" name="conferencingenabled"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.conferencingenabled}/>
                                </div>
                                <div className="col-md-6">
                                    <label className="float-left"> Contact IP  </label>
                                    <input type="text" className="form-control" name="contactip"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.contactip} maxLength="10"/>
                                </div>
                            </div>

                            <div className="row form-group">
                                <div className="col-md-6">
                                    <label className="float-left"> Disable Imforwebrtc  </label>
                                    <input type="text" className="form-control" name="disableimforwebrtc"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.disableimforwebrtc}/>
                                </div>
                                <div className="col-md-6">
                                    <label className="float-left"> DSCP packet marking  </label>
                                    <input type="text" className="form-control" name="dscppacketmarking"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.dscppacketmarking}/>
                                </div>
                            </div>

                            <div className="row form-group">
                                <div className="col-md-6 custom-radio">
                                    <label> DTMF All  </label>

                                    <div onChange={this.inputChangeHandler}>
                                        <input className="margin-left-10"
                                        type="radio" value="1" name="dtmfall"
                                        checked={this.state.data.dtmfall === "1"}
                                        /> Yes
                                        <input className="margin-left-10"
                                        type="radio" value="0" name="dtmfall"
                                        checked={this.state.data.dtmfall === "0"}/> No
                                    </div>
                                </div>
                                {/* <div className="col-md-6">
                                    <label className="float-left"> Dtm forder  </label>
                                    <input type="text" className="form-control" name="dtmforder"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.dtmforder}/>
                                </div> */}
                            </div>

                            <div className="row form-group order-component">

                                <div className="col-md-6">
                                    <label className="float-left"> DTMF Order  </label>
                                    <input type="text" className="form-control" name="dtmforder"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.dtmforder} disabled/>
                                </div>

                                <div className="col-md-custom">
                                    <select  className="form-control"
                                        onChange={this.selectChangeHandler} name="dtmforder">
                                        {this.dtmforderItems}
                                    </select>

                                </div>
                                <div className="col-md-2">
                                    
                                    <button  type="button" onClick={this.pushHandler} id="dtmforder"
                                        className="button-custom btn btn-primary btn-width">
                                        Add
                                    </button>

                                </div>

                                <div className="col-md-2">
                                    
                                    <button  type="button" onClick={this.popHandler} id="dtmforder"
                                        className="button-custom btn btn-secondary btn-width ml-3">
                                        Remove
                                    </button>

                                </div>

                            </div>

                            <div className="row form-group">
                                <div className="col-md-6">
                                    <label className="float-left"> Exporter Id  </label>
                                    <input type="text" className="form-control" name="exporter_id"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.exporter_id}/>
                                </div>
                                <div className="col-md-6">
                                    <label className="float-left"> Forced Contact  </label>
                                    <input type="text" className="form-control" name="forcedcontact"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.forcedcontact}/>
                                </div>
                            </div>

                            <div className="row form-group">
                                <div className="col-md-6 custom-radio">
                                    <label> Force Ptime  </label>

                                    <div onChange={this.inputChangeHandler}>
                                        <input className="margin-left-10"
                                        type="radio" value="1" name="forceptime"
                                        checked={this.state.data.forceptime === "1"}
                                        /> Yes
                                        <input className="margin-left-10"
                                        type="radio" value="0" name="forceptime"
                                        checked={this.state.data.forceptime === "0"}/> No
                                    </div>

                                </div>
                                <div className="col-md-6 custom-radio">
                                    <label> Force Ptime 3g  </label>

                                    <div onChange={this.inputChangeHandler}>
                                        <input className="margin-left-10"
                                        type="radio" value="1" name="forceptime3g"
                                        checked={this.state.data.forceptime3g === "1"}
                                        /> Yes
                                        <input className="margin-left-10"
                                        type="radio" value="0" name="forceptime3g"
                                        checked={this.state.data.forceptime3g === "0"}/> No
                                    </div>
                                </div>
                            </div>

                            <div className="row form-group">
                                <div className="col-md-6 custom-radio">
                                    <label> Forwarding Enabled  </label>

                                    <div onChange={this.inputChangeHandler}>
                                        <input className="margin-left-10"
                                        type="radio" value="1" name="forwardingenabled"
                                        checked={this.state.data.forwardingenabled === "1"}
                                        /> Yes
                                        <input className="margin-left-10"
                                        type="radio" value="0" name="forwardingenabled"
                                        checked={this.state.data.forwardingenabled === "0"}/> No
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label className="float-left"> Forwarding Number  </label>
                                    <input type="text" className="form-control" name="forwardingnumber"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.forwardingnumber}/>
                                </div>
                            </div>

                            <div className="row form-group">
                                <div className="col-md-6 custom-radio">
                                    <label> Honor the IR Codec List 3g  </label>

                                    <div onChange={this.inputChangeHandler}>
                                        <input className="margin-left-10"
                                        type="radio" value="1" name="honortheircodeclist3g"
                                        checked={this.state.data.honortheircodeclist3g === "1"}
                                        /> Yes
                                        <input className="margin-left-10"
                                        type="radio" value="0" name="honortheircodeclist3g"
                                        checked={this.state.data.honortheircodeclist3g === "0"}/> No
                                    </div>
                                </div>
                                <div className="col-md-6 custom-radio">
                                    <label> Honor the IR Codec List Wifi  </label>

                                    <div onChange={this.inputChangeHandler}>
                                        <input className="margin-left-10"
                                        type="radio" value="1" name="honortheircodeclistwifi"
                                        checked={this.state.data.honortheircodeclistwifi === "1"}
                                        /> Yes
                                        <input className="margin-left-10"
                                        type="radio" value="0" name="honortheircodeclistwifi"
                                        checked={this.state.data.honortheircodeclistwifi === "0"}/> No
                                    </div>
                                </div>
                            </div>

                            <div className="row form-group">

                                <div className="col-md-6">
                                    <label className="float-left"> Ice  </label>
                                    <input type="text" className="form-control" name="ice"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.ice}/>
                                </div>

                                <div className="col-md-6">
                                    <label className="float-left"> Voice Mail Number </label>
                                    <input type="text" className="form-control" name="voicemailnumber"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.voicemailnumber}/>
                                </div>
                            </div>

                            <div className="row form-group">
                                <div className="col-md-6 custom-radio">
                                    <label> Ignore Symmetricnat  </label>

                                    <div onChange={this.inputChangeHandler}>
                                        <input className="margin-left-10"
                                        type="radio" value="1" name="ignoresymmetricnat"
                                        checked={this.state.data.ignoresymmetricnat === "1"}
                                        /> Yes
                                        <input className="margin-left-10"
                                        type="radio" value="0" name="ignoresymmetricnat"
                                        checked={this.state.data.ignoresymmetricnat === "0"}/> No
                                    </div>
                                </div>
                                <div className="col-md-6 custom-radio">
                                    <label> Keep Alive  </label>

                                    <div onChange={this.inputChangeHandler}>
                                        <input className="margin-left-10"
                                        type="radio" value="1" name="keepalive"
                                        checked={this.state.data.keepalive === "1"}
                                        /> Yes
                                        <input className="margin-left-10"
                                        type="radio" value="0" name="keepalive"
                                        checked={this.state.data.keepalive === "0"}/> No
                                    </div>
                                </div>
                            </div>

                            <div className="row form-group">
                                <div className="col-md-6">
                                    <label className="float-left"> Keep Alive Period  </label>
                                    <input type="number" className="form-control" name="keepaliveperiod"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.keepaliveperiod} maxLength="3" min="0"/>
                                </div>
                                <div className="col-md-6">
                                    <label className="float-left"> Listening Port  </label>
                                    <input type="text" className="form-control" name="listeningport"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.listeningport}/>
                                </div>
                            </div>

                            <div className="row form-group">
                                <div className="col-md-6">
                                    <label className="float-left"> Max Time to wait for working network in milliseconds  </label>
                                    <input type="text" className="form-control" name="maxtimetowaitforworkingnetworkinmilliseconds"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.maxtimetowaitforworkingnetworkinmilliseconds}/>
                                </div>
                                <div className="col-md-6">
                                    <label className="float-left"> NAT Traversal  </label>
                                    <input type="text" className="form-control" name="nattraversal"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.nattraversal} maxLength="10"/>
                                </div>
                            </div>

                            <div className="row form-group">
                                <div className="col-md-6 custom-radio">
                                    <label> Opus Options 3g bandwidth  </label>

                                    <div onChange={this.inputChangeHandler}>
                                        <input className="margin-left-10"
                                        type="radio" value="1" name="opusoptions3gbandwidth"
                                        checked={this.state.data.opusoptions3gbandwidth === "1"}
                                        /> Yes
                                        <input className="margin-left-10"
                                        type="radio" value="0" name="opusoptions3gbandwidth"
                                        checked={this.state.data.opusoptions3gbandwidth === "0"}/> No
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label className="float-left"> Opus Options 3g bitrate  </label>
                                    <input type="text" className="form-control" name="opusoptions3gbitrate"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.opusoptions3gbitrate}/>
                                </div>
                            </div>

                            <div className="row form-group">
                                <div className="col-md-6">
                                    <label className="float-left"> Opus Options 3g Class  </label>
                                    <input type="text" className="form-control" name="opusoptions3gclass"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.opusoptions3gclass} maxLength="2"/>
                                </div>
                                <div className="col-md-6">
                                    <label className="float-left"> Opus Options 3g Complexity  </label>
                                    <input type="text" className="form-control" name="opusoptions3gcomplexity"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.opusoptions3gcomplexity}/>
                                </div>
                            </div>

                            <div className="row form-group">
                                <div className="col-md-6">
                                    <label className="float-left"> Opus Options 3g DTX  </label>
                                    <input type="text" className="form-control" name="opusoptions3gdtx"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.opusoptions3gdtx}/>
                                </div>
                                <div className="col-md-6">
                                    <label className="float-left"> Opus Options 3g Expected Packet Loss  </label>
                                    <input type="text" className="form-control" name="opusoptions3gexpectedpacketloss"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.opusoptions3gexpectedpacketloss}/>
                                </div>
                            </div>

                            <div className="row form-group">
                                <div className="col-md-6">
                                    <label className="float-left"> Opus Options 3g FEC </label>
                                    <input type="text" className="form-control" name="opusoptions3gfec"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.opusoptions3gfec}/>
                                </div>
                                <div className="col-md-6">
                                    <label className="float-left"> Opus Options 3G VBR  </label>
                                    <input type="text" className="form-control" name="opusoptions3gvbr"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.opusoptions3gvbr}/>
                                </div>
                            </div>

                            <div className="row form-group">
                                <div className="col-md-6">
                                    <label className="float-left"> Opus Options Bandwidth </label>
                                    <input type="text" className="form-control" name="opusoptionsbandwidth"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.opusoptionsbandwidth} maxLength="2"/>
                                </div>
                                <div className="col-md-6">
                                    <label className="float-left"> Opus Options Bit Rate  </label>
                                    <input type="text" className="form-control" name="opusoptionsbitrate"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.opusoptionsbitrate}/>
                                </div>
                            </div>

                            <div className="row form-group">
                                <div className="col-md-6">
                                    <label className="float-left"> Opus Options Class </label>
                                    <input type="text" className="form-control" name="opusoptionsclass"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.opusoptionsclass} maxLength="2"/>
                                </div>
                                <div className="col-md-6">
                                    <label className="float-left"> Opus Options Complexity  </label>
                                    <input type="text" className="form-control" name="opusoptionscomplexity"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.opusoptionscomplexity}/>
                                </div>
                            </div>

                            <div className="row form-group">
                                <div className="col-md-6 custom-radio">
                                    <label> Opus Options DTX </label>

                                    <div onChange={this.inputChangeHandler}>
                                        <input className="margin-left-10"
                                        type="radio" value="1" name="opusoptionsdtx"
                                        checked={this.state.data.opusoptionsdtx === "1"}
                                        /> Yes
                                        <input className="margin-left-10"
                                        type="radio" value="0" name="opusoptionsdtx"
                                        checked={this.state.data.opusoptionsdtx === "0"}/> No
                                    </div>
                                </div>
                                <div className="col-md-6 custom-radio">

                                    <label> Opus Options VBR </label>

                                    <div onChange={this.inputChangeHandler}>
                                        <input className="margin-left-10"
                                        type="radio" value="1" name="opusoptionsvbr"
                                        checked={this.state.data.opusoptionsvbr === "1"}
                                        /> Yes
                                        <input className="margin-left-10"
                                        type="radio" value="0" name="opusoptionsvbr"
                                        checked={this.state.data.opusoptionsvbr === "0"}/> No
                                    </div>

                                </div>
                            </div>

                            <div className="row form-group">
                                <div className="col-md-6 custom-radio">
                                    <label>Opus Options Fec </label>
                                    <div onChange={this.inputChangeHandler}>
                                        <input className="margin-left-10"
                                        type="radio" value="1" name="opusoptionsfec"
                                        checked={this.state.data.opusoptionsfec === "1"}
                                        /> Yes
                                        <input className="margin-left-10"
                                        type="radio" value="0" name="opusoptionsfec"
                                        checked={this.state.data.opusoptionsfec === "0"}/> No
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label className="float-left"> Opus Options Expected Packet Loss  </label>
                                    <input type="text" className="form-control" name="opusoptionsexpectedpacketloss"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.opusoptionsexpectedpacketloss}/>

                                </div>
                            </div>

                            <div className="row form-group">
                                <div className="col-md-6">
                                    <label className="float-left"> PTime  </label>
                                    <input type="number" className="form-control" name="ptime"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.ptime} maxLength="2" min="0"/>
                                </div>
                                <div className="col-md-6">
                                    <label className="float-left"> PTime 3G </label>
                                    <input type="number" className="form-control" name="ptime3g"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.ptime3g}  maxLength="2" min="0"/>
                                </div>

                            </div>

                            <div className="row form-group">
                                <div className="col-md-6 custom-radio">
                                    <label> Push Block Reg  </label>

                                    <div onChange={this.inputChangeHandler}>
                                        <input className="margin-left-10"
                                        type="radio" value="1" name="pushblockreg"
                                        checked={this.state.data.pushblockreg === "1"}
                                        /> Yes
                                        <input className="margin-left-10"
                                        type="radio" value="0" name="pushblockreg"
                                        checked={this.state.data.pushblockreg === "0"}/> No
                                    </div>
                                </div>
                                <div className="col-md-6 custom-radio">
                                    <label> Requires Registration For Outgoing Calls </label>

                                    <div onChange={this.inputChangeHandler}>
                                        <input className="margin-left-10"
                                        type="radio" value="1" name="requiresregistrationforoutgoingcalls"
                                        checked={this.state.data.requiresregistrationforoutgoingcalls === "1"}
                                        /> Yes
                                        <input className="margin-left-10"
                                        type="radio" value="0" name="requiresregistrationforoutgoingcalls"
                                        checked={this.state.data.requiresregistrationforoutgoingcalls === "0"}/> No
                                    </div>
                                </div>

                            </div>

                            <div className="row form-group">
                                <div className="col-md-6 custom-radio">
                                    <label> RFC2833 Enforce Duration in 8khz Timestamp units  </label>

                                    <div onChange={this.inputChangeHandler}>
                                        <input className="margin-left-10"
                                        type="radio" value="1" name="rfc2833enforcedurationin8khztimestampunits"
                                        checked={this.state.data.rfc2833enforcedurationin8khztimestampunits === "1"}
                                        /> Yes
                                        <input className="margin-left-10"
                                        type="radio" value="0" name="rfc2833enforcedurationin8khztimestampunits"
                                        checked={this.state.data.rfc2833enforcedurationin8khztimestampunits === "0"}/> No
                                    </div>

                                </div>
                                <div className="col-md-6 custom-radio">
                                    <label> RFC2833 Negotiate Only 8khz Clockrate </label>

                                    <div onChange={this.inputChangeHandler}>
                                        <input className="margin-left-10"
                                        type="radio" value="1" name="rfc2833negotiateonly8khzclockrate"
                                        checked={this.state.data.rfc2833negotiateonly8khzclockrate === "1"}
                                        /> Yes
                                        <input className="margin-left-10"
                                        type="radio" value="0" name="rfc2833negotiateonly8khzclockrate"
                                        checked={this.state.data.rfc2833negotiateonly8khzclockrate === "0"}/> No
                                    </div>
                                </div>

                            </div>

                            <div className="row form-group">
                                <div className="col-md-6">
                                    <label className="float-left"> RTP Port Range End </label>
                                    <input type="text" className="form-control" name="rtpportrangeend"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.rtpportrangeend}/>
                                </div>
                                <div className="col-md-6">
                                    <label className="float-left"> RTP Port Range Start  </label>
                                    <input type="text" className="form-control" name="rtpportrangestart"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.rtpportrangestart}/>
                                </div>

                            </div>

                            <div className="row form-group">
                                <div className="col-md-6 custom-radio">
                                    <label> Send Audio Back </label>

                                    <div onChange={this.inputChangeHandler}>
                                        <input className="margin-left-10"
                                        type="radio" value="1" name="sendaudioback"
                                        checked={this.state.data.sendaudioback === "1"}
                                        /> Yes
                                        <input className="margin-left-10"
                                        type="radio" value="0" name="sendaudioback"
                                        checked={this.state.data.sendaudioback === "0"}/> No
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label className="float-left"> Sip is Host Prefix Length </label>
                                    <input type="number" className="form-control" name="sipishostprefixlength"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.sipishostprefixlength} maxLength="1" min="0"/>
                                </div>

                            </div>

                            <div className="row form-group">
                                <div className="col-md-6">
                                    <label className="float-left"> Sip is Regserver  </label>
                                    <input type="text" className="form-control" name="sipisregserver"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.sipisregserver}/>
                                </div>
                                <div className="col-md-6">
                                    <label className="float-left"> Stun </label>
                                    <input type="text" className="form-control" name="stun"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.stun}/>
                                </div>

                            </div>

                            <div className="row form-group">
                                <div className="col-md-6">
                                    <label className="float-left"> Stun Username </label>
                                    <input type="text" className="form-control" name="stunusername"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.stunusername}/>
                                </div>
                                <div className="col-md-6">
                                    <label className="float-left"> Stun Password  </label>
                                    <input type="text" className="form-control" name="stunpassword"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.stunpassword}/>
                                </div>

                            </div>

                            <div className="row form-group">
                                <div className="col-md-6 custom-radio">
                                    <label> Subscribe for Voicemail  </label>

                                    <div onChange={this.inputChangeHandler}>
                                        <input className="margin-left-10"
                                        type="radio" value="1" name="subscribeforvoicemail"
                                        checked={this.state.data.subscribeforvoicemail === "1"}
                                        /> Yes
                                        <input className="margin-left-10"
                                        type="radio" value="0" name="subscribeforvoicemail"
                                        checked={this.state.data.subscribeforvoicemail === "0"}/> No
                                    </div>
                                </div>

                                {/* <div className="col-md-6">
                                    <label className="float-left"> Video Codecorder  </label>
                                    <input type="text" className="form-control" name="videocodecorder"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.videocodecorder}/>
                                </div> */}

                            </div>

                            <div className="row form-group order-component">

                                <div className="col-md-6">
                                    <label className="float-left"> Video Codec Order  </label>
                                    <input type="text" className="form-control" name="videocodecorder"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.videocodecorder} disabled/>
                                </div>

                                <div className="col-md-custom">
                                    <select  className="form-control"
                                        onChange={this.selectChangeHandler} name="videocodecorder">
                                        {this.videocodecorderItems}
                                    </select>

                                </div>
                                <div className="col-md-2">
                                    
                                    <button  type="button" onClick={this.pushHandler} id="videocodecorder"
                                        className="button-custom btn btn-primary btn-width">
                                        Add
                                    </button>

                                </div>

                                <div className="col-md-2">
                                    
                                    <button  type="button" onClick={this.popHandler} id="videocodecorder"
                                        className="button-custom btn btn-secondary btn-width ml-3">
                                        Remove
                                    </button>

                                </div>

                            </div>

                            <div className="row form-group order-component">

                                <div className="col-md-6">
                                    <label className="float-left"> Video Codec Order 3G  </label>
                                    <input type="text" className="form-control" name="videocodecorder3g"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.videocodecorder3g} disabled/>
                                </div>

                                <div className="col-md-custom">
                                    <select  className="form-control"
                                        onChange={this.selectChangeHandler} name="videocodecorder3g">
                                        {this.videocodecorder3gItems}
                                    </select>

                                </div>
                                <div className="col-md-2">
                                    
                                    <button  type="button" onClick={this.pushHandler} id="videocodecorder3g"
                                        className="button-custom btn btn-primary btn-width">
                                        Add
                                    </button>

                                </div>

                                <div className="col-md-2">
                                    
                                    <button  type="button" onClick={this.popHandler} id="videocodecorder3g"
                                        className="button-custom btn btn-secondary btn-width ml-3">
                                        Remove
                                    </button>

                                </div>

                            </div>

                            <div className="row form-group order-component">

                                <div className="col-md-6">
                                    <label className="float-left"> Video Codec Order Webrtc  </label>
                                    <input type="text" className="form-control" name="videocodecorderwebrtc"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.videocodecorderwebrtc} disabled/>
                                </div>

                                <div className="col-md-custom">
                                    <select  className="form-control"
                                        onChange={this.selectChangeHandler} name="videocodecorderwebrtc">
                                        {this.videocodecorderwebrtcItems}
                                    </select>

                                </div>
                                <div className="col-md-2">
                                    
                                    <button  type="button" onClick={this.pushHandler} id="videocodecorderwebrtc"
                                        className="button-custom btn btn-primary btn-width">
                                        Add
                                    </button>

                                </div>

                                <div className="col-md-2">
                                    
                                    <button  type="button" onClick={this.popHandler} id="videocodecorderwebrtc"
                                        className="button-custom btn btn-secondary btn-width ml-3">
                                        Remove
                                    </button>

                                </div>

                            </div>

                            {/* <div className="row form-group">

                                <div className="col-md-6">
                                    <label className="float-left"> Video Codecorder 3g </label>
                                    <input type="text" className="form-control" name="videocodecorder3g"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.videocodecorder3g}/>
                                </div>

                                <div className="col-md-6">
                                    <label className="float-left"> Video Codecorder WebRTC </label>
                                    <input type="text" className="form-control" name="videocodecorderwebrtc"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.videocodecorderwebrtc}/>
                                </div>

                            </div> */}

                            <div className="row form-group">

                                <div className="col-md-6">
                                    <label className="float-left"> Video Dims 3g </label>
                                    <input type="text" className="form-control" name="videodims3g"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.videodims3g}/>
                                </div>

                                <div className="col-md-6">
                                    <label className="float-left"> Video Dims Wifi </label>
                                    <input type="text" className="form-control" name="videodimswifi"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.videodimswifi}/>
                                </div>

                            </div>

                            <div className="row form-group">

                                <div className="col-md-6">
                                    <label className="float-left"> Video FPS 3g </label>
                                    <input type="text" className="form-control" name="videofps3g"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.videofps3g} maxLength="3"/>
                                </div>

                                <div className="col-md-6">
                                    <label className="float-left"> Video FPS Wifi </label>
                                    <input type="text" className="form-control" name="videofpswifi"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.videofpswifi} maxLength="3"/>
                                </div>

                            </div>

                            <div className="row form-group">

                                <div className="col-md-6">
                                    <label className="float-left"> Video Max kbps 3g </label>
                                    <input type="text" className="form-control" name="videomaxkbps3g"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.videomaxkbps3g}/>
                                </div>

                                <div className="col-md-6">
                                    <label className="float-left"> Video Max kbps Wifi </label>
                                    <input type="text" className="form-control" name="videomaxkbpswifi"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.videomaxkbpswifi}/>
                                </div>

                            </div>

                            <div className="row form-group">

                                <div className="col-md-6">
                                    <label className="float-left"> Video Min kbps 3g </label>
                                    <input type="text" className="form-control" name="videominkbps3g"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.videominkbps3g}/>
                                </div>

                                <div className="col-md-6">
                                    <label className="float-left"> Video Min kbps Wifi </label>
                                    <input type="text" className="form-control" name="videominkbpswifi"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.videominkbpswifi}/>
                                </div>

                            </div>


                            <div className="row form-group">
                                <div className="col-md-12">

                                    <button type="button" onClick={this.cancelHandler} className="button-custom btn shadow-2 btn-secondary btn-width">
                                        CANCEL
                                    </button>
                                    <button type="button" onClick={this.saveHandler} className="button-custom btn shadow-2 btn-success btn-width">
                                        SAVE
                                    </button>

                                </div>
                            </div>
                        </CardBody>
                    </Card>
                    <div className="col-md-2 col-sm-0"></div>
                </div>
            </Fragment>
		);

	}
}

export default Details;
