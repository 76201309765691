import {
    Home,
    Users,
    Globe,
    Clipboard,
    Cloud,
    Star,
    Smartphone
} from 'react-feather';

export const MENUITEMS = [
    {
        title: 'Dashboard', icon: Home, type: 'link', badgeType: 'primary', active: true, path: '/dashboard'
    },
    {
        title: 'Domain Management', icon: Globe, type: 'link', active: false, path: '/messaging'
    },
    {
        title: 'App Management', icon: Smartphone, type: 'link', active: false, path: '/app_management'
    },
    {
        title: 'Subscribers', icon: Star, type: 'link', active: false, path: '/subscribers'
    },
    {
        title: 'Users', icon: Users, type: 'link', active: false, path: '/users'
    },
    {
        title: 'Netsapiens', icon: Cloud, type: 'link', active: false, path: '/netsapiens'
    },
    {
        title: 'Client Profiles', icon: Clipboard, type: 'link', active: false, path: '/client_profiles'
    },
]
