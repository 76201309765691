import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import axios from 'axios';
import moment from 'moment-timezone';

import Auxi from '../../hoc/Auxi';
import AppLayout from '../settings/CustomAppTabLayout';
//import Avatar from '../../assets/images/user/message-avatar.png';

import Toast from '../../components/Widgets/CustomToast';
import Spinner from '../../components/Widgets/Spinner';
// import Websocket from 'react-websocket';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentDots } from '@fortawesome/free-solid-svg-icons';

import '../../assets/scss/custom-tab-styles/style.scss';

import VoiceMail from '../../assets/images/Visual-Voice-Mail.png';
import Settings from '../../assets/images/Gear-Button.png';
import Engage from '../../assets/images/MoveMy_Call.png';
import CallParking from '../../assets/images/Park.png';
import MoveCall from '../../assets/images/MoveMy_Call.png';
import { ThemeProvider } from 'react-bootstrap';

const Index = (props) => {

    console.log(props);
    const [openVoicemail, setOpenVoicemail] = useState(false);
    const [openSettings, setOpenSettings] = useState(false);
    const [openEngage, setOpenEngage] = useState(false);
    const [openCallParking, setOpenCallParking] = useState(false);

    const [redirect, setRedirect] = useState(null);
    const [showEngage, setShowEngage] = useState(null);
    const [backgroundImage, setBackGroundImage] = useState(localStorage.getItem("custom_tab_background"));

    let url = decodeURIComponent(window.location.href)

    url = url.substring(
        url.lastIndexOf("?username="),
        url.length
    );

    useEffect(() => {
        var url = new URL(window.location.href);
        //setCloudId(url.searchParams.get("cloud_id"));
        //checkIfShowEngage(url.searchParams.get("username"), url.searchParams.get("cloud_id"))
        // on signin always get logos
        const payLoad = {
            cloud_id: url.searchParams.get("cloud_id"),
        };
        axios.post(`${process.env.REACT_APP_API_URL}getCompanyLogos`, payLoad)
            .then(response => {
                localStorage.setItem("signin_logo", response.data.signin_logo);
                localStorage.setItem("dashboard_logo", response.data.dashboard_logo);
                localStorage.setItem("custom_tab_background", response.data.custom_tab_background);
                setBackGroundImage(response.data.custom_tab_background);
            }).catch(err => {
                console.log(err.response.data);
            });


    }, []);

    const checkIfShowEngage = (username, cloudId) => {

        const payLoad = {
            domain: username.split('@')[1],
            cloud_id: cloudId,
            extension: username.split('@')[0],
        }

        axios.post(`${process.env.REACT_APP_API_URL}customWebTabCheck`, payLoad)
            .then(response => {
                setShowEngage(true);
            })
            .catch(err => {
                console.log(err);
                setShowEngage(false);
            });
    }
    const voiceMailPath = "/custom_tab/voice_messages" + url;
    const vmailSettingsPath = "/custom_tab/vmail_settings" + url;
    const callParkingPath = "/custom_tab/call_parkings" + url;
    const engagePath = "/custom_tab/engage" + url;

    if (openVoicemail) {
        setOpenVoicemail(false);
        setRedirect(<Redirect to={voiceMailPath} />);
    }


    if (openSettings) {
        setOpenSettings(false);
        setRedirect(<Redirect to={vmailSettingsPath} />);
    }

    if (openCallParking) {
        setOpenCallParking(false);
        setRedirect(<Redirect to={callParkingPath} />);
    }
    if (openEngage) {
        setOpenEngage(false);
        setRedirect(<Redirect to={engagePath} />);
    }
    return (
        <Auxi>
            {redirect}
            <div className="bg-full auth-wrapper"
                style={{ backgroundColor: "white" }}>
                <div className="row" style={{ top: "100px", position: "absolute" }}>
                    {/* <div className="col-6" style={{ marginTop: "20px" }}>
                        <div className="voice-mail-index setting-image">
                            <a href="sip:*97?dialAction=autoCall"><img src={MoveCall} /> </a>
                            <h5> Move <br /> Call</h5>
                        </div>
                    </div> */}
                    <div className="col-6">
                        <div className="voice-mail-index setting-image" style={{ marginTop: "20px" }}>
                            <img src={VoiceMail} onClick={e => setOpenVoicemail(true)} />
                            <h5 style={{color:'black'}}> Visual <br /> VM</h5>
                  
                        </div>
                    </div>
                   
                    <div className={"col-6"} style={{ marginTop: "20px" }}>
                        <div className="settings-index setting-image">
                            <img src={Settings} onClick={e => setOpenSettings(true)} />
                            <h5 style={{color:'black'}}>User <br /> Settings</h5>
                        </div>
                    </div>
                    <div className="col-12" style={{ marginTop: "20px" }}>
                        <div className="call-parking-index setting-image">
                            <img src={CallParking} onClick={e => setOpenCallParking(true)} />
                            <h5 style={{color:'black'}}> Call <br /> Park</h5>
                        </div>
                    </div>

                    {
                        showEngage ?
                            <div className={"col-12" } style={{ marginTop: "20px" }}>
                                <div className="engage-index setting-image">
                                    <img src={Engage} onClick={e => setOpenEngage(true)} />
                                    <h5>Engage</h5>
                                </div>
                            </div>
                            :
                            null

                    }

                </div>
            </div>
            <div className="bottom-logo">
                <img width="300" src={`${process.env.REACT_APP_API_URL + localStorage.getItem("custom_tab_background")}`} alt=""/>
            </div>

        </Auxi>
    );
}

export default Index;
