import React from 'react';
import axios from 'axios';

import Auxi from '../../hoc/Auxi';
import AppLayout from './CustomAppTabLayout';
import { toast } from 'react-toastify';


import '../../assets/scss/custom-tab-styles/style.scss';

class Engage extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            recordType: 'vmail/new',
            voiceMails: [],
            username: "Daniyal",
            loading: false,
            audioSource: '',
            showModal: false,
            data: {},
            showEngage: null,

        };
        this.playPauseRef = [];
    }

    checkIfShowEngage = (username, cloudId) => {
        const payLoad = {
            domain: username.split('@')[1],
            cloud_id: cloudId,
            extension: username.split('@')[0],
        } 

        axios.post(`${process.env.REACT_APP_API_URL}customWebTabCheck`, payLoad)
        .then(response=>{
            this.setState({showEngage: true});
        })
        .catch(err=>{
            this.setState({showEngage: false});
        });
    }

    inputChangeHandler = (evt) => {

        const target = evt.target;
        const name = target.name;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        if (value === true) {
            value = "yes";
        }

        if (value === false) {
            value = "no";
        }
        

        let data = this.state.data;
        data[name] = value;
        this.setState({ data });
    }
    
    getDeviceInfo = (userName, uType, password, cloudId) => {
    
        const payLoad = new FormData();
        payLoad.append('device', "sip:" + userName.split("@")[0] + "@" + userName.split("@")[1]);
        payLoad.append('domain', userName.split("@")[1]);
        payLoad.append('cloudId', cloudId);
        
        axios.post(`${process.env.REACT_APP_API_NS_URL}?format=json&object=device&action=read&utype=${uType}&cloud_username=${userName}&cloud_password=${password}`, payLoad)
        .then(response=>{
            this.setState({username: (response.data[0].aor_user) +'@'+((response.data[0].sub_login).split("@")[1])});

        })
        .catch(err=>{
            toast.error(err.message);
        });
    }

    componentDidMount(){

        // infoOnExistingUser will provide the settings data

        let url = decodeURIComponent(window.location.href)

        url = url.substring(
            url.lastIndexOf("?username="),
                    url.length
                );
        this.setState({url: url});

                let username = url.substring(
            url.lastIndexOf("?username=") + 10,
                    url.lastIndexOf("&password=")
                );
        let password = url.substring(
            url.lastIndexOf("&password=") + 10,
            url.lastIndexOf("&cloud_id=")
        );
        let cloudId = url.substring(url.lastIndexOf("&cloud_id=") + 10,url.lastIndexOf("&direct="));
        let direct = url.substring(url.lastIndexOf("&direct=") + 8,url.length);
        cloudId = cloudId.replace("*", "");
        password = encodeURIComponent(password);
        
        this.setState({username: username});
        this.setState({password: password});
        this.setState({cloudId: cloudId});
        this.setState({direct: direct});
        this.checkIfShowEngage(username, cloudId)

        let uType = 's';
        if(username.includes("QR")){
            // Here everything is based upon superuser
            this.getDeviceInfo(username, uType, password, cloudId);
            this.setState({uType: uType});
            return
        }

        // Here we will get everything based upon simple user
        uType = 'u';
        const payLoad = {
            user: username,
            password: password,
            cloud_id: cloudId
        }

        axios.post(`${process.env.REACT_APP_API_NS_URL}/signin`, payLoad)
        .then(response=>{
            username =  username.split("@")[0] + "@" + response.data.domain;
            this.setState({username: username});
            this.setState({uid: response.data.uid});
            this.setState({uType: uType});
        })
        .catch(err=>{
            toast.error(err.message);
        });
        

    }


    render() {

        return(
            <Auxi>
                {
                    this.state.direct === "0" && this.state.showEngage !== null ?
                        <AppLayout cloud_id={this.state.cloudId}
                            display_name = {this.state.username}
                            ownContact = {this.state.ownContact}
                            fromPage = "engage"
                            showEngage={this.state.showEngage}
                            url={this.state.url}/>
                    : null
                }

                <div className = "CustomMainIndexApp" style={{marginBottom: "10px"}}>
                    <div className="row voice-message-layout">
                        <object type="text/html" data="https://admin.ccaasmavenir.com/toolbar_widget/#login" style={{width: "100%", height:"calc(100vh - 70px)", paddingTop: "50px"}}>
                        </object>
                    </div>
                </div>
            </Auxi>

        );

    }

}

export default Engage;
