import React, { Fragment } from 'react';
import { toast } from 'react-toastify';
import {Card,CardBody,CardHeader, Input, Toast } from 'reactstrap';
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios';
import { Typeahead } from 'react-bootstrap-typeahead';
import { MDBDataTableV5 } from 'mdbreact';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneVolume } from '@fortawesome/free-solid-svg-icons'
import CallingNumberModal from './callingNumberModal';


import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import QRModal from './qrModal'

import '../../assets/scss/app-styles/extensions.scss'

class Extensions extends React.Component {
    constructor(props) {
        super(props);
        this.state ={
            selectedDomain: "",
            allUsersCheckedList: "",
            usersAdded: "",
            usersRemoved: "",
            style: 'custom-spinner',
            cc: '',
            body: '',
            to: '',
            subject: '',
            qrModal: false,
            showTemplateSave: false,
            callingNumberModal: false,
            availableCallingNumbers: [],
            selectedCallingPhoneNumber: [],
            domainList: [],
            usersList: [],
            sortBy: "first_name",
            allContactsCheckedList: "",
            globalEmailList: "",
            globalCheckMark: JSON.stringify(new Array()),
            uiConfig: [],
            
        };
    }

    headers = {
        headers: {
            Token: localStorage.getItem("token"),
        }
    }

    sortItems = [
        <option value="first_name"> First Name</option>,
        <option value="user"> Extension</option>
    ]

    sortChangeHandler = (evt) => {
        this.setState({sortBy: evt.target.value});
        const subscribersList = (this.state.mySubList).sort(this.GetSortOrder(evt.target.value));
        this.setState({mySubList: subscribersList});
        this.refreshUsers(subscribersList, this.state.allUsersCheckedList, this.state.globalCheckMark, this.state.allContactsCheckedList);
        this.setState({searchValue: ""});
        this.setState({filteredListOfUsers: null});
        
    }

    componentDidMount () {
        this.setState({style: 'custom-spinner'});
        const payLoad = {
            cloud_id: JSON.parse(localStorage.getItem('creds')).cloud_id,
        }
        axios.post(`${process.env.REACT_APP_API_URL}getAllDomains`, payLoad, this.headers)
        .then(response=>{

            let items = [];
            response.data.map((row, index)=>(
                items.push({domain: ""+row.domain})
            ));

            this.setState({domainList: items});
            this.setState({style: 'custom-spinner-hide'});
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({style: 'custom-spinner-hide'});
        });

    }

    userChangeHandler = (evt) => {
        let allUsersCheckedList = this.state.allUsersCheckedList;

        if(evt.target.getAttribute("data-name") === "off"){

            //add
            if(allUsersCheckedList === ""){
                
                allUsersCheckedList = "&" + evt.target.getAttribute("data-value") +"&"
                //setAllUsersCheckedList(evt.target.value)
            }
            else{
                allUsersCheckedList += ",&" + evt.target.getAttribute("data-value") +"&";
            }
        }
        else{
            //remove
            const list =  (allUsersCheckedList).split(",");
            allUsersCheckedList = "";
            for(let i=0; i<list.length; i++){

                // if found the value ignore
                if(list[i] !== "&" + evt.target.getAttribute("data-value") +"&"){

                    if(allUsersCheckedList === ""){
                
                        allUsersCheckedList = list[i];
                    }
                    else{
                        allUsersCheckedList += "," + list[i];
                    }
                }
                
            }
        }

        this.setState({allUsersCheckedList: allUsersCheckedList});
        if(this.state.filteredListOfUsers){
            this.refreshUsers(this.state.filteredListOfUsers, allUsersCheckedList, this.state.globalCheckMark, this.state.allContactsCheckedList);
        } else {
            this.refreshUsers(this.state.mySubList, allUsersCheckedList, this.state.globalCheckMark, this.state.allContactsCheckedList);
        }

    }

    contactsToggleHandler = (evt) => {
        const value = evt.target.getAttribute("data-value")
        const name = evt.target.getAttribute("data-name")
        
        let allContactsCheckedList = this.state.allContactsCheckedList;

        if(name === "off"){

            //add
            if(allContactsCheckedList === ""){
                
                allContactsCheckedList = "&" +value +"&"
                //setAllUsersCheckedList(evt.target.value)
            }
            else{
                allContactsCheckedList += ",&" + value +"&";
            }
        }
        else{
            //remove
            const list =  (allContactsCheckedList).split(",");
            allContactsCheckedList = "";
            for(let i=0; i<list.length; i++){

                // if found the value ignore
                if(list[i] !== "&" + value +"&"){

                    if(allContactsCheckedList === ""){
                
                        allContactsCheckedList = list[i];
                    }
                    else{
                        allContactsCheckedList += "," + list[i];
                    }
                }
                
            }
        }

        this.setState({allContactsCheckedList: allContactsCheckedList});
        if(this.state.filteredListOfUsers){
            this.refreshUsers(this.state.filteredListOfUsers, this.state.allUsersCheckedList, this.state.globalCheckMark, allContactsCheckedList);
        } else {
            this.refreshUsers(this.state.mySubList, this.state.allUsersCheckedList, this.state.globalCheckMark, allContactsCheckedList);
        }
    }

    GetSortOrder(prop) {    
        return function(a, b) {    
            if (a[prop] > b[prop]) {    
                return 1;    
            } else if (a[prop] < b[prop]) {    
                return -1;    
            }    
            return 0;    
        }    
    }
    callerIdHandler = (user) => {
        this.setState({ extensionSelected:user})
        this.refreshCallingIdLists(user);
    }

    refreshCallingIdLists = (user) => {
        
        let payLoad = {
            domain: this.state.selectedDomain,
            cloud_id: JSON.parse(localStorage.getItem('creds')).cloud_id,
        }
        this.setState({style: 'custom-spinner'});
        axios.post(`${process.env.REACT_APP_API_URL}getAvailableCallingNumbers`, payLoad, this.headers)
        .then(response=>{
            
            let items = [];
            response.data.map((row, index)=>{
                items.push({phone_number: row.phone_number.toString()})
            });
            this.setState({availableCallingNumbers: items});
            this.setState({style: 'custom-spinner-hide'});
            
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({style: 'custom-spinner-hide'});
        });

        payLoad = {
            domain: this.state.selectedDomain,
            cloud_id: JSON.parse(localStorage.getItem('creds')).cloud_id,
            user: user
        }
        axios.post(`${process.env.REACT_APP_API_URL}getAssignedCallingNumbers`, payLoad, this.headers)
        .then(response=>{
            let dataFromApi = response.data;
            let columns =[
                {
                    label: 'Id',
                    field: 'id',
                },
                {
                    label: 'Phone Number',
                    field: 'phone_number',
                },
                {
                    label: 'Extension',
                    field: 'extension',
                },
                {
                    label: 'Domain',
                    field: 'domain',
                },
                {
                    label: 'Delete',
                    field: 'edit',
                    sort: 'disabled',
                }
            ];
    
            let dataModified = null;
            if(dataFromApi != null)
            {
                dataModified = dataFromApi.map((row)=>{
                    return ({...row, edit:  <div className="edit-column">
                    <i className="far fa-trash-alt data-table-icon"
                        onClick={e => this.removePhoneNumberHandler(row.phone_number)}
                    ></i>
                    </div>});
                });
    
            }
            this.setState({assignedCallingNumbers: {columns: columns, rows: dataModified}});
            this.setState({callingNumberModal: true});
            this.setState({style: 'custom-spinner-hide'});
            
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({style: 'custom-spinner-hide'});
        });
    }

    removePhoneNumberHandler = (phoneNumber) => {
        console.log("Delete Phonenumber "+phoneNumber);
        const payLoad = {
            phone_number : phoneNumber,
            domain: this.state.selectedDomain,
            cloud_id: JSON.parse(localStorage.getItem('creds')).cloud_id,
        };
        this.setState({style: 'custom-spinner'});
        axios.post(`${process.env.REACT_APP_API_URL}deleteAssignedCallingNumber`, payLoad, this.headers)
        .then(response=>{
            this.refreshCallingIdLists(this.state.extensionSelected);
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({style: 'custom-spinner-hide'});
        });
    }

    CallingNumberChangeHandler = (result) => {
        if(result.length === 0){
            this.setState({selectedCallingNumber: ""});
            return;
        }
        this.setState({selectedCallingPhoneNumber: result[0].phone_number});
        this.setState({style: 'custom-spinner'});

    }
    saveCallingNumberHandler =() => {
        if(!this.state.selectedCallingPhoneNumber){
            toast.error("Please select the calling number first!")
            return;
        }
        const payLoad = {
            domain: this.state.selectedDomain,
            cloud_id: JSON.parse(localStorage.getItem('creds')).cloud_id,
            extension: this.state.extensionSelected,
            phone_number: this.state.selectedCallingPhoneNumber
        }
        this.setState({style: 'custom-spinner'});
        axios.post(`${process.env.REACT_APP_API_URL}assignCallingNumber`, payLoad, this.headers)
        .then(response=>{
            this.setState({style: 'custom-spinner-hide'});
            this.refreshCallingIdLists(this.state.extensionSelected);    
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({style: 'custom-spinner-hide'});
        });
    }

    deleteSharedVmHander =(evt) => {
        
        const payLoad = {
            for_user: this.state.sharedVmUser,
            vm_user: evt.target.getAttribute("data-id"),
            domain: this.state.selectedDomain,
            cloud_id: JSON.parse(localStorage.getItem('creds')).cloud_id
        }
        this.setState({style: 'custom-spinner'});
        axios.post(`${process.env.REACT_APP_API_URL}deleteSharedVM`, payLoad, this.headers)
        .then(response=>{
            let sharedVmList = this.state.sharedVmList;
            const index = sharedVmList.findIndex((item,index) => {
                return item.vm_user === evt.target.getAttribute("data-id")
            })
            
            sharedVmList.splice(index, 1);
            this.setState({sharedVmList: sharedVmList});
            this.setState({style: 'custom-spinner-hide'});
            
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({style: 'custom-spinner-hide'});
        });
    }

    toggleCallingNumberHandler = () => {
        this.setState({callingNumberModal: !this.state.callingNumberModal});
    }


 
    // domainEngageToggleHandler = (evt) => {
    //     this.setState({style: 'custom-spinner-show'});
    //     const payLoad = {
    //         action : evt.target.getAttribute("data-action"),
    //         domain : this.state.selectedDomain,
    //         user_id: JSON.parse(localStorage.getItem('creds')).id,
    //         username: JSON.parse(localStorage.getItem('creds')).username,
    //     }
    //     axios.post(`${process.env.REACT_APP_API_URL}domainEngageToggle`, payLoad, this.headers)
    //     .then(response=>{

    //         this.setState({style: 'custom-spinner-hide'});
    //         // this.setState({domainEnabled: !this.state.domainEnabled});
            
    //     })
    //     .catch(err=>{
    //         this.setState({style: 'custom-spinner-hide'});
    //         toast.error(err.message);
    //     });
    // }

    // checkDomainEngageEnabled = (domain) => {
    //     const payLoad = {
    //         domain : domain,
    //         user_id: JSON.parse(localStorage.getItem('creds')).id,
    //     }
    //     axios.post(`${process.env.REACT_APP_API_URL}checkDomainEngageEnabled`, payLoad, this.headers)
    //     .then(response=>{
    //         this.setState({domainEnabled: true});
            
    //     })
    //     .catch(err=>{
    //         this.setState({domainEnabled: false});
    //     });
    // }

    domainChangeHandler = (result) => {
        if(result.length === 0){
            this.setState({selectedDomain: ""});
            this.setState({usersList: []});
            return;
        }
        this.setState({selectedDomain: result[0].domain});
        this.setState({style: 'custom-spinner'});
        let payLoad = new FormData();
        payLoad.append('domain', result[0].domain);
        payLoad.append('fields', "user,domain,dir,email,first_name,last_name,subscriber_login,scope");
        payLoad.append('cloud_id', JSON.parse(localStorage.getItem('creds')).cloud_id);

        axios.post(`${process.env.REACT_APP_API_NS_URL}?format=json&object=subscriber&action=read&utype=s&cloud_username=${JSON.parse(localStorage.getItem('creds')).superuser}&cloud_password=${"password"}`, payLoad)
        .then(response=>{
            //const allowedScopes = ["system user", "Office Manager", "Elite", "Professional", "Advanced", "Call Center Agent", "Site Manager", "Super User"]
            // let subscribersList = (response.data).filter((el) => {
            //     return el.scope !== "";
            // });
            this.setState({searchValue: ""});
            this.setState({filteredListOfUsers: null});
            let subscribersList = (response.data).sort(this.GetSortOrder("first_name"));
            this.setState({sortBy: "first_name"});
            //const subscribersList = response.data;
            this.setState({mySubList: subscribersList});
            this.setState({globalCheckMark: JSON.stringify(new Array())});
            this.getCheckedInfo();
            //this.checkDomainEngageEnabled(result[0].domain)
            this.setState({checkedAll: false});
            
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({style: 'custom-spinner-hide'});
        });

    }

    saveHandler = () => {
        const checkedList = this.state.allUsersCheckedList;
        let allUsersList = [];
        const subscribersList = this.state.mySubList;
        for(let i=0; i<subscribersList.length; i++){
            const info = {
                extension: subscribersList[i].user,
                allowed: checkedList.includes("&"+subscribersList[i].user+"&")
            }
            console.log(info)
            allUsersList.push(info);
        }
        this.setState({style: 'custom-spinner'});
        const payLoad = {
            cloud_id : JSON.parse(localStorage.getItem('creds')).cloud_id,
            domain : this.state.selectedDomain,
            username: JSON.parse(localStorage.getItem('creds')).username,
            user_id: JSON.parse(localStorage.getItem('creds')).id,
            list: allUsersList,
        }
        axios.post(`${process.env.REACT_APP_API_URL}checkExtensions`, payLoad, this.headers)
        .then(response=>{

            toast.success("Successfully Saved!");
            this.setState({style: 'custom-spinner-hide'});
            
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({style: 'custom-spinner-hide'});
        });
        
    }

    getCheckedInfo = () => {
        this.setState({style: 'custom-spinner'});
        
        const payLoad = {
            cloud_id : JSON.parse(localStorage.getItem('creds')).cloud_id,
            domain : this.state.selectedDomain,
            user_id: JSON.parse(localStorage.getItem('creds')).id
        }
        axios.post(`${process.env.REACT_APP_API_URL}getExtensionsChecked`, payLoad, this.headers)
        .then(response=>{

            const extensionsChecked= response.data.extensionsList;
            const contactsChecked = response.data.contactsList;
            let allUsersCheckedList = "";
            let allContactsCheckedList = "";
            for(let i=0; i< extensionsChecked.length; i++){

                if(allUsersCheckedList === ""){
                    allUsersCheckedList = "&" + extensionsChecked[i].extension +"&";
                }else{
                    allUsersCheckedList += ",&"+extensionsChecked[i].extension +"&";
                }
            }

            for(let i=0; i< contactsChecked.length; i++){

                if(allContactsCheckedList === ""){
                    allContactsCheckedList = "&" + contactsChecked[i].extension +"&";
                }else{
                    allContactsCheckedList += ",&"+contactsChecked[i].extension +"&";
                }
            }

            this.setState({allUsersCheckedList: allUsersCheckedList});
            this.setState({originalAllUsersCheckedList: extensionsChecked})
            this.refreshUsers(this.state.mySubList, allUsersCheckedList, this.state.globalCheckMark, allContactsCheckedList);
            this.setState({style: 'custom-spinner-hide'});
            
        })
        .catch(err=>{
            this.setState({allUsersCheckedList: ""});
            this.refreshUsers(this.state.mySubList, "", this.state.globalCheckMark,this.state.allContactsCheckedList);
            this.setState({style: 'custom-spinner-hide'});
        });
    }

    // refreshUsers = (data, allUsersCheckedList, allContactsCheckedList) => {
    //     let items = [];
    //     data.map((row, index)=>{
    //         return(
    //         items.push(<div key={index} className="form-group col-3">
    //             <div className="checkbox-container">
    //                 <label className="name-label">{row.first_name} {row.last_name}</label>
    //                 <div  className="checkbox checkbox-primary" >
    //                     <input id={"checkbox-primary-"+index} type="checkbox" value={row.subscriber_login.split("@")[0]}
    //                         onChange={this.userChangeHandler} checked={allUsersCheckedList.includes("&"+row.subscriber_login.split("@")[0]+"&")}/>
    //                     <label htmlFor={"checkbox-primary-"+index}>{row.user}</label>
    //                 </div>

    //                 <div> 
    //                     <label style={{marginRight: "10px"}}>Engage</label>
    //                     {
    //                         allContactsCheckedList.includes("&"+row.user+"&")?
    //                             <i className="fas fa-toggle-on data-table-icon" 
    //                                 onClick={this.tabToggleHandler} 
    //                                 title="Disable Engage on Custom Web Tab" 
    //                                 data-value={row.user} style={{cursor:"pointer", color: "green"}}
    //                                 data-name="on"/>
    //                         :
    //                             <i className="fas fa-toggle-off data-table-icon" 
    //                                 title="Enable Engage on Custom Web Tab"
    //                                 onClick={this.tabToggleHandler}  
    //                                 data-value={row.user} style={{cursor:"pointer"}}
    //                                 data-name="off"/>

    //                     }
                        
    //                 </div>
    //             </div>
    //         </div>))
    //     });

    //     this.setState({usersList: items});
    // }
    checkAll = (evt) => {
        if(evt.target.getAttribute("data-action") === "add"){
            let allUsersCheckedList = this.state.allUsersCheckedList;
            for(let i=0; i<(this.state.mySubList).length; i++){
                if(allUsersCheckedList === ""){
                    allUsersCheckedList = "&" + this.state.mySubList[i].subscriber_login.split("@")[0] +"&";
                }else{
                    allUsersCheckedList += ",&"+this.state.mySubList[i].subscriber_login.split("@")[0] +"&";
                }
            }
            this.setState({allUsersCheckedList:allUsersCheckedList});
            this.setState({searchValue: ""});
            this.setState({filteredListOfUsers: null});
            this.refreshUsers(this.state.mySubList, allUsersCheckedList, this.state.globalCheckMark, this.state.allContactsCheckedList);
            this.setState({checkedAll: true});
        }
        else{
            const data = this.state.originalAllUsersCheckedList;
            let allUsersCheckedList = "";
            if(data){
                for(let i=0; i< data.length; i++){

                    if(allUsersCheckedList === ""){
                        allUsersCheckedList = "&" + data[i].extension +"&";
                    }else{
                        allUsersCheckedList += ",&"+data[i].extension +"&";
                    }
                }
            }
            this.setState({allUsersCheckedList:allUsersCheckedList});
            this.setState({searchValue: ""});
            this.setState({filteredListOfUsers: null});
            this.refreshUsers(this.state.mySubList, allUsersCheckedList, this.state.globalCheckMark, this.state.allContactsCheckedList);
            this.setState({checkedAll: false});
        }

        toast.info("Please click Save button below to Save changes")
    }
    
    onChangeHandler = (evt) => {
        this.setState({[evt.target.name]: evt.target.value});
    }

    sendEmailHandler = (evt) => {

        const subscribersList = this.state.mySubList;

        if(!this.state.to || !this.state.subject || !this.state.body){
            toast.error("To, Subject and Body are mandatory fields to send an email!");
            return;
        }
        
        for(let i=0; i< (subscribersList).length; i++){

            let email = subscribersList[i].email.split(";")[0];
            let body =  this.state.body;
            if(email !== "" &&  (this.state.to).includes(email)){
                body = body.replace("FIRST_NAME_HERE", subscribersList[i].first_name);
                body = body.replace("LAST_NAME_HERE", subscribersList[i].last_name);
                const link = `<a href="https://devpanel.snapcom.com/getQR?username=${subscribersList[i].subscriber_login}&cloud_id=${JSON.parse(localStorage.getItem("creds")).cloud_id}">Click Here</a>`
                body = body.replace("LINK_HERE", link);
                const payLoad = {
                    recepient: email,
                    cc: this.state.cc,
                    subject: this.state.subject,
                    body: body,
                    user_id: JSON.parse(localStorage.getItem('creds')).id,
                };
        
                axios.post(`${process.env.REACT_APP_API_URL}emailLink`, payLoad, this.headers)
                .then(response=>{
                    console.log("Sucessfully sent email to "+ subscribersList[i].subscriber_login.split("@")[0]);
                    this.setState({qrModal: false});
                })
                .catch(err=>{
                    toast.error("Unable to send an email to "+ subscribersList[i].subscriber_login.split("@")[0]);
                });
            }
        }
    }

    toggleHandler = () => {
        this.setState({qrModal: !this.state.qrModal});
    };

    qrHandler = async (event) => {
        this.setState({cc: ''});
        this.setState({body: ''});
        this.setState({subject: ''});
        this.setState({template_name: ''});
        this.setState({showTemplateSave: false});
        this.setState({to: event.target.getAttribute("data-email")});
        await this.getEmailTemplates();
        this.setState({qrModal: true});
    }

    qrCodeHandler = async (event) => {
        //csc:username:password@CODE
        this.setState({cc: ''});
        this.setState({body: ''});
        this.setState({subject: ''});
        this.setState({template_name: ''});
        this.setState({showTemplateSave: false});

        let emailList = this.state.globalEmailList;

        if (emailList === ""){
            toast.error('No user with email selected! Please select users first, from checkboxes at the left.');
            return;
        }
        this.setState({to: emailList});
        await this.getEmailTemplates();
        this.setState({qrModal: true});
    }

    templateSelectedHandler = (evt) => {

        const data = this.state.templatesData;
        this.setState({body: data[evt.target.getAttribute("data-id")]["body"]});
        this.setState({template_name: data[evt.target.getAttribute("data-id")]["name"]});
        this.setState({subject: data[evt.target.getAttribute("data-id")]["subject"]});
        this.setState({showTemplateSave: true});
        this.setState({templateSelected: true});
    }

    deleteTemplate = (evt) => {
        if(JSON.parse(localStorage.getItem('creds')).user_type !== "admin"){
            toast.error("You are not allowed to delete email template");
            return
        }

        confirmAlert({
            title: 'Are you sure you want to delete this email template?',
            message: '',
            buttons: [
                {
                    label: 'Delete',
                    onClick: () => {
                        this.setState({style: 'custom-spinner'});
                        const payLoad = {
                            id: evt.target.getAttribute("data-id"),
                            user_id: JSON.parse(localStorage.getItem('creds')).id,
                        }

                        axios.post(`${process.env.REACT_APP_API_URL}deleteTemplate`, payLoad, this.headers)
                        .then(response=>{
                            toast.success("Successfully Deleted Template");
                            this.getEmailTemplates();
                            this.setState({style: 'custom-spinner-hide'});
                            this.setState({body: ''});
                            this.setState({subject: ''});
                            this.setState({template_name: ''});
                            this.setState({showTemplateSave: false});
                            this.setState({templateSelected: false});
                        })
                        .catch(err=>{
                            toast.error("Failed to delete email template");
                            this.setState({style: 'custom-spinner-hide'});
                        });
                    }
                },
                {
                    label: 'Cancel',
                    onClick: () => {
                    }
                }
            ]
            
        });
    }

    getEmailTemplates = () => {
        this.setState({style: 'custom-spinner'});
        const payLoad = {
            user_id : JSON.parse(localStorage.getItem('creds')).id
        }
        axios.post(`${process.env.REACT_APP_API_URL}getTemplates`, payLoad, this.headers)
        .then(response=>{
            const data = response.data;
            let items = [];
            let templatesData = {};
            data.map((row, index)=>{
                templatesData[row.id] = {
                    body: row.body,
                    name: row.name,
                    subject: row.subject
                }
                return (items.push(
                    <div key={"templates"+index} className="template-container">
                        <label className="name-label" onClick={this.templateSelectedHandler} data-id={row.id}>{row.name}</label>
                        <div onClick={this.deleteTemplate} data-id={row.id}> <i data-id={row.id} className="fa fa-times-circle"></i> </div>
                    </div>)
            )});
            this.setState({templatesData: templatesData});
            this.setState({templatesList: items});
            this.setState({style: 'custom-spinner-hide'});
            
        })
        .catch(err=>{
            this.setState({templatesData: null});
            this.setState({templatesList: []});
            this.setState({style: 'custom-spinner-hide'});
        });
    }

    editorOnChangeHandler = (evt) => {
        this.setState({body: evt.editor.getData()})
    }

    toggleTemplateSave = (evt) => {
        if(JSON.parse(localStorage.getItem('creds')).user_type !== "admin"){
            toast.error("You are not allowed to add email template");
            return
        }
        if(!this.state.showTemplateSave){
            this.setState({body: ''});
            this.setState({subject: ''});
            this.setState({template_name: ''});
            this.setState({templateSelected: false});
        }
        this.setState({showTemplateSave: !this.state.showTemplateSave})
    }

    saveTemplateHandler = (evt) => {
        if(JSON.parse(localStorage.getItem('creds')).user_type !== "admin"){
            toast.error("You are not allowed to update email template");
            return
        }
        this.setState({style: 'custom-spinner'});
        if(!this.state.template_name || !this.state.body){
            toast.error("Template name and body are mandatory");
            this.setState({style: 'custom-spinner-hide'});
            return;
        }
        const payLoad = {
            name: this.state.template_name,
            body: this.state.body,
            user_id: JSON.parse(localStorage.getItem('creds')).id,
        }

        if(this.state.subject !== ''){
            payLoad["subject"] = this.state.subject;
        }

        axios.post(`${process.env.REACT_APP_API_URL}templateCreateUpdate`, payLoad, this.headers)
        .then(response=>{
            toast.success("Successfully saved email template");
            this.setState({templateSelected: true});
            this.getEmailTemplates();
        })
        .catch(err=>{
            toast.error("Failed to save email template");
            this.setState({style: 'custom-spinner-hide'});
        });
    }

    searchChangeHandler = (evt) => {
        this.setState({searchValue: evt.target.value});
        let users = this.state.mySubList;
        users = users.filter((value, index) => {
            const firstName = value.first_name.toLowerCase();
            const lastName = value.last_name.toLowerCase();
            const user = value.user.toLowerCase();
            const searchResult =  evt.target.value.toLowerCase();
            if(user.includes(searchResult) || firstName.includes(searchResult) || lastName.includes(searchResult)){
                return true;
            } else {
                return false;
            }
        });
        this.setState({filteredListOfUsers: users});
        this.refreshUsers(users, this.state.allUsersCheckedList, this.state.globalCheckMark, this.state.allContactsCheckedList);
    }

    checkIfEveryElementExisits = (data, globalCheckMark) => {
        for (let i=0; i< data.length; i++){
            if (!globalCheckMark.includes(JSON.stringify(data[i]))){
                return false;
            }
        }
        return true;
    }

    checkIfUiElementExists = (user, uiConfig) => {
        for (let i=0; i< uiConfig.length; i++){
            if(uiConfig[i].user === user){
                return true;
            }
        }
        return false;
    }

    checkIfDomainUiElementExists = (uiConfig) => {
        for (let i=0; i< uiConfig.length; i++){
            if(uiConfig[i].user === "*" && uiConfig[i].domain === this.state.selectedDomain){
                return true;
            }
        }
        return false;
    }

    // refreshUiConfig = (evt) => {
    //     this.setState({style: 'custom-spinner'});
        
    //     const formPayLoad = new FormData();
    //     formPayLoad.append('format', 'json');
    //     formPayLoad.append('domain', this.state.selectedDomain);
    //     formPayLoad.append('config_name', 'PORTAL_VIDEO');
    //     formPayLoad.append('distinct', '');
    //     formPayLoad.append('cloud_id', JSON.parse(localStorage.getItem('creds')).cloud_id);
    //     axios.post(`${process.env.REACT_APP_API_NS_URL}?format=json&object=uiconfig&action=read&utype=${'s'}&cloud_username=${JSON.parse(localStorage.getItem('creds')).superuser}&cloud_password=${"password"}`, formPayLoad)
    //     .then(response=>{
    //         this.setState({uiConfig: response.data})
    //         this.refreshUsers(this.state.mySubList, this.state.allUsersCheckedList, this.state.globalCheckMark, response.data);
    //         this.setState({style: 'custom-spinner-hide'});
    //     })
    //     .catch(err=>{
    //         toast.error(err.message);
    //         this.setState({style: 'custom-spinner-hide'});
    //     });
    // }

    uiConfigCreateHandler = (evt) => {
        if(this.checkIfDomainUiElementExists(this.state.uiConfig)){
            toast.warn("Cannot connect meet as domain level connect meet is enabled")
            return;
        }
        this.setState({style: 'custom-spinner'});
        
        const formPayLoad = new FormData();
        formPayLoad.append('config_name', 'PORTAL_VIDEO');
        formPayLoad.append('reseller', '*');
        formPayLoad.append('domain', this.state.selectedDomain);
        formPayLoad.append('user', evt.target.getAttribute('data-value'));
        formPayLoad.append('role', '*');
        formPayLoad.append('login_type', '*');
        formPayLoad.append('config_value', 'yes');
        formPayLoad.append('decription', '');
        formPayLoad.append('allow_lesser_scope', '');
        
        formPayLoad.append('cloud_id', JSON.parse(localStorage.getItem('creds')).cloud_id);
        axios.post(`${process.env.REACT_APP_API_NS_URL}?format=json&object=uiconfig&action=create&utype=${'s'}&cloud_username=${JSON.parse(localStorage.getItem('creds')).superuser}&cloud_password=${"password"}`, formPayLoad)
        .then(response=>{
            
            this.refreshUiConfig()

        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({style: 'custom-spinner-hide'});
        });
    } 

    uiConfigDeleteHandler = (evt) => {
        this.setState({style: 'custom-spinner'});
        
        const formPayLoad = new FormData();
        formPayLoad.append('config_name', 'PORTAL_VIDEO');
        formPayLoad.append('reseller', '*');
        formPayLoad.append('domain', this.state.selectedDomain);
        formPayLoad.append('user', evt.target.getAttribute('data-value'));
        formPayLoad.append('role', '*');
        formPayLoad.append('login_type', '*');
        formPayLoad.append('config_value', 'yes');
        formPayLoad.append('hostname', '*');
        
        formPayLoad.append('cloud_id', JSON.parse(localStorage.getItem('creds')).cloud_id);
        axios.post(`${process.env.REACT_APP_API_NS_URL}?format=json&object=uiconfig&action=delete&utype=${'s'}&cloud_username=${JSON.parse(localStorage.getItem('creds')).superuser}&cloud_password=${"password"}`, formPayLoad)
        .then(response=>{
            
            this.refreshUiConfig();
            
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({style: 'custom-spinner-hide'});
        });
    } 
    domainUiConfigCreateHandler = (evt) => {
        this.setState({style: 'custom-spinner'});
        
        const formPayLoad = new FormData();
        formPayLoad.append('config_name', 'PORTAL_VIDEO');
        formPayLoad.append('reseller', '*');
        formPayLoad.append('domain', this.state.selectedDomain);
        formPayLoad.append('user', "*");
        formPayLoad.append('role', '*');
        formPayLoad.append('login_type', '*');
        formPayLoad.append('config_value', 'yes');
        formPayLoad.append('decription', '');
        formPayLoad.append('allow_lesser_scope', '');
        
        formPayLoad.append('cloud_id', JSON.parse(localStorage.getItem('creds')).cloud_id);
        axios.post(`${process.env.REACT_APP_API_NS_URL}?format=json&object=uiconfig&action=create&utype=${'s'}&cloud_username=${JSON.parse(localStorage.getItem('creds')).superuser}&cloud_password=${"password"}`, formPayLoad)
        .then(response=>{
            
            this.refreshUiConfig();
            this.deleteUiConfigForAllUsers()

        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({style: 'custom-spinner-hide'});
        });
    } 

    deleteUiConfigForAllUsers = () => {
        this.setState({style: 'custom-spinner'});
        const subscribersList = this.state.mySubList;

        let count = 0;
        for(let i=0; i<subscribersList.length; i++){
            const formPayLoad = new FormData();
            formPayLoad.append('config_name', 'PORTAL_VIDEO');
            formPayLoad.append('reseller', '*');
            formPayLoad.append('domain', this.state.selectedDomain);
            formPayLoad.append('role', '*');
            formPayLoad.append('login_type', '*');
            formPayLoad.append('config_value', 'yes');
            formPayLoad.append('hostname', '*');
            formPayLoad.append('cloud_id', JSON.parse(localStorage.getItem('creds')).cloud_id);
            formPayLoad.append('user', subscribersList[i].user);
            axios.post(`${process.env.REACT_APP_API_NS_URL}?format=json&object=uiconfig&action=delete&utype=${'s'}&cloud_username=${JSON.parse(localStorage.getItem('creds')).superuser}&cloud_password=${"password"}`, formPayLoad)
            .then(response=>{
                // I know this count is unsafe but logic is solid.
                count++;
                console.log("i: "+ i + " count: "+ count)
                if (count === subscribersList.length){
                    // this means its last user now I can refresh
                    this.refreshUiConfig();
                }
            })
            .catch(err=>{
                toast.error(err.message);
                this.setState({style: 'custom-spinner-hide'});
            });
        }

        
    }

    domainUiConfigDeleteHandler = (evt) => {
        this.setState({style: 'custom-spinner'});
        
        const formPayLoad = new FormData();
        formPayLoad.append('config_name', 'PORTAL_VIDEO');
        formPayLoad.append('reseller', '*');
        formPayLoad.append('domain', this.state.selectedDomain);
        formPayLoad.append('user', "*");
        formPayLoad.append('role', '*');
        formPayLoad.append('login_type', '*');
        formPayLoad.append('config_value', 'yes');
        formPayLoad.append('hostname', '*');
        
        formPayLoad.append('cloud_id', JSON.parse(localStorage.getItem('creds')).cloud_id);
        axios.post(`${process.env.REACT_APP_API_NS_URL}?format=json&object=uiconfig&action=delete&utype=${'s'}&cloud_username=${JSON.parse(localStorage.getItem('creds')).superuser}&cloud_password=${"password"}`, formPayLoad)
        .then(response=>{
            
            this.refreshUiConfig();
            
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({style: 'custom-spinner-hide'});
        });
    } 

    refreshUsers = (data, allUsersCheckedList, globalCheckMark, allContactsCheckedList) => {

        let columns =[
            {
                label: <div  className="checkbox checkbox-primary mg-20" >
                <input id={"checkbox-primary-head1"} type="checkbox"
                    onChange={this.multiSelect} value = {JSON.stringify(data)}
                    checked={this.checkIfEveryElementExisits(data, globalCheckMark)}/>
                    <label htmlFor={"checkbox-primary-head1"}></label>
                </div>,
                field: 'checkbox',
                sort: 'disabled',
            },
            {
                label: 'User',
                field: 'user',
            },
            {
                label: 'First Name',
                field: 'first_name',
            },
            {
                label: 'Last Name',
                field: 'last_name',
            },
            {
                label: 'Scope',
                field: 'scope',
            },
            {
                label: 'CallerID Numbers',
                field: 'callerIdNumbers',
                sort: 'disabled',
            },
            {
                label: 'Connect Softphone',
                field: 'enable',
                sort: 'disabled',
            },
            {
                label: 'System Contacts',
                field: 'contacts',
                sort: 'disabled',
            },
            // {
            //     label: 'Connect Meet',
            //     field: 'config',
            //     sort: 'disabled',
            // },
            {
                label: 'Send QR Code',
                field: 'qr',
                sort: 'disabled',
            },
        ];

        let dataModified = null;
        if(data != null)
        {
            dataModified = data.map((row, index)=>{
                return ({...row, checkbox: <div  className="checkbox checkbox-primary mg-20" >
                    <input id={"checkbox-table-"+index} type="checkbox" value={JSON.stringify(row)}
                        onChange={this.singleSelect} checked={globalCheckMark.includes(JSON.stringify(row))}/>
                        <label htmlFor={"checkbox-table-"+index}></label>
                    </div>,
                      callerIdNumbers:<FontAwesomeIcon
                      title='Resynch All Domains'
                      icon={faPhoneVolume} className="icon-check"
                      style={{ fontSize: "18px", cursor: "pointer", marginLeft: '20px', transform: 'rotate(-45deg)'}}
                      onClick={e => this.callerIdHandler(row.user)}/>,
                    enable: <div> 
                        {
                            allUsersCheckedList.includes("&"+row.subscriber_login.split("@")[0]+"&")?
                                <i className="fas fa-toggle-on data-table-icon mg-20" 
                                    onClick={this.userChangeHandler} 
                                    title="Disable Provisioning on Custom Web Tab" 
                                    data-value={row.subscriber_login.split("@")[0]} style={{color: "green"}}
                                    data-name="on"/>
                            :
                                <i className="fas fa-toggle-off data-table-icon mg-20" 
                                    title="Enable Provisioning on Custom Web Tab"
                                    onClick={this.userChangeHandler}  
                                    data-value={row.subscriber_login.split("@")[0]}
                                    data-name="off"/>

                        }
                    </div>, 
                    contacts: <div> 
                        {
                            allContactsCheckedList.includes("&"+row.user+"&")?
                                <i className="fas fa-toggle-on data-table-icon mg-20" 
                                    onClick={this.contactsToggleHandler} 
                                    title="Disable System Contacts Filtering" 
                                    data-value={row.user} style={{color: "green"}}
                                    data-name="on"/>
                            :
                                <i className="fas fa-toggle-off data-table-icon mg-20" 
                                    title="Enable System Contacts Filtering"
                                    onClick={this.contactsToggleHandler}  
                                    data-value={row.user}
                                    data-name="off"/>

                        }
                        
                    </div>, 
                    // config: <div> 
                    //     {
                    //         this.checkIfUiElementExists(row.user, uiConfig)?
                    //             <i className="fas fa-toggle-on data-table-icon mg-20" 
                    //                 onClick={this.uiConfigDeleteHandler} 
                    //                 title="Delete PORTAL VIDEO Config" 
                    //                 data-value={row.user} style={{color: "green"}}
                    //                 data-name="on"/>
                    //         :
                    //             <i className="fas fa-toggle-off data-table-icon mg-20" 
                    //                 title="Create PORTAL VIDEO Config"
                    //                 onClick={this.uiConfigCreateHandler}  
                    //                 data-value={row.user}
                    //                 data-name="off"/>

                    //     }
                        
                    // </div>, 
                    qr: <i className="fas fa-qrcode data-table-icon mg-20"
                    onClick={this.qrHandler} data-id={row.user} data-email={row.email}></i>});
            });

        }

        this.setState({dataTable: {columns: columns, rows: dataModified}});
    }

    multiSelect = (event) => {
        let emailList = "";
        const values = JSON.parse(event.target.value);
        for(let i=0; i< (values).length; i++){
            if(event.target.checked){
                emailList += values[i].email + ", "
            }
        }

        if (emailList.length > 0){
            emailList =  emailList.slice(0, -2);
        }
        this.setState({globalEmailList: emailList});

        if(event.target.checked) {
            this.setState({globalCheckMark: event.target.value});
            this.refreshUsers(this.state.mySubList, this.state.allUsersCheckedList, event.target.value, this.state.allContactsCheckedList);
        } else {
            this.setState({globalCheckMark: JSON.stringify(new Array())});
            this.refreshUsers(this.state.mySubList, this.state.allUsersCheckedList, JSON.stringify(new Array()), this.state.allContactsCheckedList);
        }
        
    }

    singleSelect = (event) => {
        const value = JSON.parse(event.target.value);
        let emailList =  this.state.globalEmailList;

        if(event.target.checked){
            if (emailList.length > 0){
                //this means that there are already some values in the list
                emailList += ", " + value.email
            } else {
                emailList += value.email
            }
        } else {
            // first checking with ", " that means its either first or second in n
            emailList =  emailList.replaceAll(value.email+ ", ", "")

            // if its the last in n items
            emailList =  emailList.replaceAll(", " + value.email, "")

            // if its the only one
            emailList =  emailList.replaceAll(value.email, "")
        }
        this.setState({globalEmailList: emailList});

        if(event.target.checked) {
            let tempCheckMark = JSON.parse(this.state.globalCheckMark);
            tempCheckMark.push(value)
            this.setState({globalCheckMark: JSON.stringify(tempCheckMark)});
            this.refreshUsers(this.state.mySubList, this.state.allUsersCheckedList, JSON.stringify(tempCheckMark), this.state.allContactsCheckedList);
        } else {
            
            let tempCheckMark = this.state.globalCheckMark;
            tempCheckMark = tempCheckMark.replaceAll(event.target.value + ",", "")
            tempCheckMark = tempCheckMark.replaceAll(event.target.value, "")
            tempCheckMark = tempCheckMark.replaceAll("},]", "}]")
            this.setState({globalCheckMark: tempCheckMark});
            this.refreshUsers(this.state.mySubList, this.state.allUsersCheckedList, tempCheckMark, this.state.allContactsCheckedList);
        }

    }


    render () {
        return (
            <Fragment>
                <Breadcrumb parent="App" title="App Management" />

                <QRModal
                    show={this.state.qrModal}
                    toggleHandler={this.toggleHandler}
                    onChangeHandler = {this.onChangeHandler}
                    sendEmailHandler = {this.sendEmailHandler}
                    editorOnChangeHandler={this.editorOnChangeHandler}
                    cc = {this.state.cc}
                    body= {this.state.body}
                    to = {this.state.to}
                    subject= {this.state.subject}
                    templatesList = {this.state.templatesList}
                    showTemplateSave={this.state.showTemplateSave}
                    template_name={this.state.template_name}
                    toggleTemplateSave={this.toggleTemplateSave}
                    templateSelected={this.state.templateSelected}
                    saveTemplateHandler={this.saveTemplateHandler}/>
                    <CallingNumberModal
                    show={this.state.callingNumberModal}
                    toggleHandler={this.toggleCallingNumberHandler}
                    availableCallingNumbers={this.state.availableCallingNumbers}
                    assignedCallingNumbers={this.state.assignedCallingNumbers}
                    CallingNumberChangeHandler={this.CallingNumberChangeHandler}
                    saveCallingNumberHandler={this.saveCallingNumberHandler}
                    selectedUser={this.state.extensionSelected}
                />
                <div className={"loader-box " + this.state.style} >
                    <div className="loader">
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    </div>
                </div>
                <div className="container-fluid main-container">
                    <div className="col-md-1 col-sm-0"></div>
                    <Card className="col-md-10 col-sm-12">
                        <CardHeader>
                            <h5>Choose Domain</h5>
                        </CardHeader>
                        <CardBody className="dropdown-basic">
                            <div id="the-basics">
                                <form className="theme-form">
                                    <div className="form-group">
                                        <i className="fa fa-search type-ahead-search-icon"/>
                                        <Typeahead
                                            id="basic-typeahead"
                                            className="search-domains"
                                            labelKey="domain"
                                            multiple={false}
                                            options={this.state.domainList}
                                            onChange={this.domainChangeHandler}
                                            placeholder="Choose a domain..."
                                        />
                                    </div>
                                </form>
                            </div>
                            { this.state.selectedDomain !== "" && this.state.dataTable?
                                <div className="row">

                                    <div className="col-md-12 col-sm-12 domain-level-toggles"> 
                                        <label style={{marginRight: "10px"}}>Domain Level Connect Softphone</label><br/>
                                        {
                                            this.state.checkedAll?
                                                <i className="fas fa-toggle-on data-table-icon" 
                                                    onClick={this.checkAll} 
                                                    title="Disable Connect Softfone For All Users Below"
                                                    style={{color: "green"}}
                                                    data-action="remove"/>
                                            :
                                                <i className="fas fa-toggle-off data-table-icon" 
                                                    title="Enable Connect Softfone For All Users Below"
                                                    onClick={this.checkAll}
                                                    data-action="add"/>

                                        }
                        
                                    </div>

                                    {/* <div className="col-md-6 col-sm-12 domain-level-toggles"> 
                                        <label style={{marginRight: "10px"}}>Domain Level Connect Meet</label> <br/>
                                        {
                                            this.checkIfDomainUiElementExists(this.state.uiConfig)?
                                                <i className="fas fa-toggle-on data-table-icon" 
                                                    onClick={this.domainUiConfigDeleteHandler} 
                                                    title="Disable Connect Meet For Domain"
                                                    style={{color: "green"}}
                                                    data-name="on"/>
                                            :
                                                <i className="fas fa-toggle-off data-table-icon" 
                                                    title="Enable Connect Meet For Domain"
                                                    onClick={this.domainUiConfigCreateHandler}
                                                    data-name="off"/>

                                        }
                        
                                    </div> */}
                                </div>
                                
                                : null
                            }
                        </CardBody>
                    </Card>
                    <div className="col-md-1 col-sm-0"></div>
                </div>
                {
                    this.state.selectedDomain !== "" && this.state.dataTable?

                        <div className="container-fluid main-container">
                        
                            <Card className="col-md-12 col-sm-12">
                                <CardHeader>
                                    <h5>Subscribers</h5>
                                </CardHeader>
                                <CardBody className="dropdown-basic">
                                        
                                    {/* <div  className="checkbox checkbox-primary check-all-provision">
                                        <input id={"checkbox-primary-all"} type="checkbox"
                                            onChange={this.checkAll} checked={this.state.checkedAll}/>
                                        <label htmlFor={"checkbox-primary-all"}>Enable Provisioning of all Users</label>
                                    </div> */}
                                    <MDBDataTableV5 hover entriesOptions={[10, 25, 50, 100]} 
                                        entries={10}
                                        pagesAmount={4} data={this.state.dataTable} 
                                        searchTop  searchBottom={false}/>
                                    
                                    <div className="btn-container">
                                        <button className="btn btn-primary" type="button" onClick={this.saveHandler}>Save</button>
                                        <button className="btn btn-secondary" type="button" onClick={this.qrCodeHandler} style={{marginLeft: "15px"}}>
                                            Send QR Code
                                        </button>
                                    </div>
                                </CardBody>
                                
                            </Card>
                                
                        
                        </div>
                    :
                    null
                }
            </Fragment>
        );
    }
    
};

export default Extensions;