import React, {useEffect} from 'react';
import CKEditors from "react-ckeditor-component";
import {Card,CardBody,CardHeader, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { MDBDataTableV5 } from 'mdbreact';

import '../../assets/scss/app-styles/qr-modal.scss'

const CallingNumberModal = (props) => {

    useEffect(() => {  
        console.log("availableCallingNumbers: ", props.availableCallingNumbers)
    }, []);
    return(
        <>

            <Modal isOpen={props.show} toggle={props.toggleHandler}>
                <ModalHeader toggle={props.toggleHandler}>{"CallID Numbers For "+props.selectedUser}</ModalHeader>
                <ModalBody className='callIdModal'>
                    <div className="container-fluid main-container">
                        <Card className="col-md-12">
                            <CardHeader>
                                <h5>Choose Number</h5>
                            </CardHeader>
                            <CardBody className="dropdown-basic">
                                <div id="the-basics">
                                    <form className="theme-form">
                                        <div className="flex-container">
                                            
                                            <div class="item item-1">
                                            <i className="fa fa-search type-ahead-search-icon"/>
                                            <Typeahead
                                                id="basic-typeahead"
                                                className="search-domains"
                                                labelKey="phone_number"
                                                multiple={false}
                                                options={props.availableCallingNumbers}
                                                onChange={props.CallingNumberChangeHandler}
                                                placeholder="Choose a Calling Number..."
                                            />
                                            </div>
                                            <div class="item item-1">
                                                <button className="btn btn-primary" type="button" onClick={props.saveCallingNumberHandler}>Save</button>
                                            </div>
                                            
                                            
                                            
                                        </div>
                                    </form>
                                </div>
                            </CardBody>
                            
                        </Card>
                    </div>
                    <div className="container-fluid main-container">
                        
                            <Card className='col-md-12'>
                                <CardHeader >
                                    <h5>Assigned Numbers</h5>
                                </CardHeader>
                                <CardBody className="dropdown-basic p-0">
                                    <MDBDataTableV5 hover entriesOptions={[10, 25, 50, 100]} 
                                        entries={10}
                                        pagesAmount={4} data={props.assignedCallingNumbers} 
                                        searchTop  searchBottom={false}/>
                                </CardBody>
                                
                            </Card>
                        </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={props.toggleHandler}>{"Cancel"}</Button>
                </ModalFooter>
            </Modal>
        </>

    )};

export default CallingNumberModal;
