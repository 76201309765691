import React, { Fragment } from 'react';
import { toast } from 'react-toastify';
import { Card, CardBody, CardHeader } from 'reactstrap';
import Breadcrumb from '../common/breadcrumb';
import { Redirect } from 'react-router';
import { MDBDataTableV5 } from 'mdbreact';
import axios from 'axios';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import '../../assets/scss/app-styles/users.scss'


class Summary extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            newRecordClicked: false,
            userIdSelected: null,
            whiteLabelSelected: null,
            style: 'custom-spinner',
            loading: true,
            backToIndex: false,
        };
    }
    headers = {
        headers: {
            Token: localStorage.getItem("token"),
        }
    }

    componentDidMount() {
        if (JSON.parse(localStorage.getItem('creds')).user_type !== "admin") {
            console.log('heree');
            this.setState({ backToIndex: true });
            return
        }
        this.refreshData();
    }

    refreshData = () => {
        this.setState({ style: 'custom-spinner' });
        const payLoad = {
            cloud_id: JSON.parse(localStorage.getItem('creds')).cloud_id,
            user_id: JSON.parse(localStorage.getItem('creds')).id,
        };

        axios.post(`${process.env.REACT_APP_API_URL}subscriberSummary`, payLoad, this.headers)
            .then(response => {
                this.createDataTable(response.data);
                this.setState({ style: 'custom-spinner-hide' });
            })
            .catch(err => {
                toast.error(err.message);
                this.setState({ style: 'custom-spinner-hide' });
            });
    }

    createDataTable = (dataFromApi) => {

        let columns = [
            {
                label: 'Id',
                field: 'id',
            },
            {
                label: 'Account Code',
                field: 'accountcode',
            },
            {
                label: 'Comapny Name',
                field: 'company',
            },
            {
                label: 'Cloud Id',
                field: 'cloud_id',
            },
            {
                label: 'Email',
                field: 'email',
            },
            {
                label: 'Phone Number',
                field: 'phone_number',
            },
            {
                label: 'Active',
                field: 'active',
            },
            {
                label: 'Edit - Delete - White Label',
                field: 'edit',
                sort: 'disabled',
            }
        ];

        let dataModified = null;
        if (dataFromApi != null) {
            dataFromApi = dataFromApi.map((row) => {
                if (row.active) {
                    row.active = "Yes"
                }
                else {
                    row.active = "No"
                }

                return ({ ...row });
            });
            dataModified = dataFromApi.map((row) => {
                return ({
                    ...row, edit: <div className="edit-column">
                        <i className="far fa-edit data-table-icon"
                            onClick={this.editHandler} data-id={row.id}></i> -
                        <i className="far fa-trash-alt data-table-icon"
                            onClick={this.deleteHandler} data-id={row.id}></i>
                        <i className="fa fa-anchor data-table-icon" title="White-Label"
                            onClick={this.whiteLabelHandler} data-id={row.id}></i>
                    </div>
                });
            });

        }
        this.setState({ dataTable: { columns: columns, rows: dataModified } });
        this.setState({ loading: false });
    }

    whiteLabelHandler = (event) => {
        console.log(event.target.getAttribute("data-id"));

        this.setState({ whiteLabelSelected: event.target.getAttribute("data-id") })
        // will call the details compnent but with data id selected here as a prop

    }
    deleteHandler = (event) => {

        confirmAlert({
            title: 'Are you sure you want to delete this subscriber?',
            message: '',
            buttons: [
                {
                    label: 'Delete',
                    onClick: () => {
                        const payLoad = {
                            id: event.target.getAttribute("data-id"),
                            user_id: JSON.parse(localStorage.getItem('creds')).id,
                        };
                        this.setState({ style: 'custom-spinner' });
                        axios.post(`${process.env.REACT_APP_API_URL}deleteSubscriberDetail`, payLoad, this.headers)
                            .then(response => {
                                toast.success("Record Deleted");
                                this.setState({ style: 'custom-spinner-hide' });
                                this.refreshData();
                            })
                            .catch(err => {
                                this.setState({ style: 'custom-spinner-hide' });
                                if (err.response) {
                                    toast.error(err.response.data);
                                    return;
                                }
                                toast.error(err.message);
                            });
                    }
                },
                {
                    label: 'Cancel',
                    onClick: () => {
                    }
                }
            ]

        });
    }

    editHandler = (event) => {
        this.setState({ userIdSelected: event.target.getAttribute("data-id") })
        // will call the details compnent but with data id selected here as a prop

    }

    addNewHandler = (evt) => {
        evt.preventDefault();

        this.setState({ newRecordClicked: true });

    }

    render() {

        let redirect = null;
        if (this.state.newRecordClicked) {
            this.setState({ newRecordClicked: false });
            redirect = <Redirect to={{
                pathname: "/subscribers/details",
                state: { id: null }
            }} />;

        }

        if (this.state.userIdSelected) {
            this.setState({ userIdSelected: null });
            redirect = <Redirect to={{
                pathname: "/subscribers/details",
                state: { id: this.state.userIdSelected }
            }} />;
        }
        if (this.state.whiteLabelSelected) {
            this.setState({ whiteLabelSelected: null });
            redirect = <Redirect to={{
                pathname: "/subscribers/white_label",
                state: { subscriberId: this.state.whiteLabelSelected }
            }} />;
        }

        if (this.state.backToIndex) {
            redirect = <Redirect to={{
                pathname: "/dashboard"
            }} />;
        }

        return (
            <Fragment>
                <Breadcrumb parent="App"  title="Subscribers" />
                <div className={"loader-box " + this.state.style} >
                    <div className="loader">
                        <div className="line bg-primary"></div>
                        <div className="line bg-primary"></div>
                        <div className="line bg-primary"></div>
                        <div className="line bg-primary"></div>
                    </div>
                </div>
                {redirect}

                <div className="MainIndex">
                    <div className="row" style={{ float: "right" }}>
                        <button onClick={this.addNewHandler} type="button" className="btn btn-rounded-custom btn-primary table-btn" >Add New </button>
                    </div>
                    <div className="users-table">
                        <MDBDataTableV5 hover entriesOptions={[10, 20, 30]} entries={10}
                            pagesAmount={4} data={this.state.dataTable} searchTop searchBottom={false} />

                    </div>
                </div>

            </Fragment>

        );

    }

}

export default Summary;
