import React, { Fragment } from 'react';
import { toast } from 'react-toastify';
import { Card, CardBody, CardHeader, Form, FormGroup, Input } from 'reactstrap';
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios';

import { MDBDataTableV5 } from 'mdbreact';


import '../../assets/scss/app-styles/domains.scss'
import { ResponsiveEmbed } from 'react-bootstrap';

class Domains extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedDomain: "",
            allDomainsCheckedList: "",
            allTabsCheckedList: "",
            style: 'custom-spinner',

        };
    }

    headers = {
        headers: {
            Token: localStorage.getItem("token"),
        }
    }

    GetSortOrder(prop) {
        return function (a, b) {
            if (a[prop] > b[prop]) {
                return 1;
            } else if (a[prop] < b[prop]) {
                return -1;
            }
            return 0;
        }
    }

    componentDidMount() {
        this.setState({ style: 'custom-spinner' });
        const payLoad = {
            cloud_id: JSON.parse(localStorage.getItem('creds')).cloud_id,
        }
        axios.post(`${process.env.REACT_APP_API_URL}getAllDomains`, payLoad, this.headers)
            .then(response => {

                const domains = (response.data).sort(this.GetSortOrder("domain"));
                this.setState({ myDomains: domains });
                this.getCheckedInfo(domains)
                this.setState({ style: 'custom-spinner-hide' });
            })
            .catch(err => {
                toast.error(err.message);
                this.setState({ style: 'custom-spinner-hide' });
            });

    }

    domainChangeHandler = (evt) => {
        let allDomainsCheckedList = this.state.allDomainsCheckedList;

        if (evt.target.checked) {

            //add
            if (allDomainsCheckedList === "") {

                allDomainsCheckedList = "&" + evt.target.value + "&"
                //setAllUsersCheckedList(evt.target.value)
            }
            else {
                allDomainsCheckedList += ",&" + evt.target.value + "&";
            }
        }
        else {
            //remove
            const list = (allDomainsCheckedList).split(",");
            allDomainsCheckedList = "";
            for (let i = 0; i < list.length; i++) {

                // if found the value ignore
                if (list[i] !== "&" + evt.target.value + "&") {

                    if (allDomainsCheckedList === "") {

                        allDomainsCheckedList = list[i];
                    }
                    else {
                        allDomainsCheckedList += "," + list[i];
                    }
                }

            }
        }

        this.setState({ allDomainsCheckedList: allDomainsCheckedList });
        if (this.state.filteredListOfDomains) {
            this.populateDomains(this.state.filteredListOfDomains, allDomainsCheckedList);
        } else {
            this.populateDomains(this.state.myDomains, allDomainsCheckedList);
        }


    }

    saveHandler = () => {
        const allDomainsList = this.state.allDomainsCheckedList.replaceAll("&", "");
        this.setState({ style: 'custom-spinner' });
        const payLoad = {
            cloud_id: JSON.parse(localStorage.getItem('creds')).cloud_id,
            username: JSON.parse(localStorage.getItem('creds')).username,
            list: allDomainsList,
            user_id: JSON.parse(localStorage.getItem('creds')).id,
        }

        axios.post(`${process.env.REACT_APP_API_URL}checkUncheckDomains`, payLoad, this.headers)
            .then(response => {

                toast.success("Successfully Saved!");
                this.setState({ searchValue: "" });
                this.populateDomains(this.state.myDomains, this.state.allDomainsCheckedList, this.state.allTabsCheckedList);
                this.setState({ style: 'custom-spinner-hide' });

            })
            .catch(err => {
                toast.error(err.message);
                this.setState({ style: 'custom-spinner-hide' });
            });

    }

    getCheckedInfo = (domains) => {
        this.setState({ style: 'custom-spinner' });
        const payLoad = {
            user_id: JSON.parse(localStorage.getItem('creds')).id,
        }
        axios.post(`${process.env.REACT_APP_API_URL}getDomainsChecked`, payLoad, this.headers)
            .then(response => {

                const domainsChecked = response.data.domainsList;
                let allDomainsCheckedList = "";
                for (let i = 0; i < domainsChecked.length; i++) {

                    if (allDomainsCheckedList === "") {
                        allDomainsCheckedList = "&" + (domainsChecked[i].domain.toLowerCase()) + "&";
                    } else {
                        allDomainsCheckedList += "&" + (domainsChecked[i].domain.toLowerCase()) + "&";
                    }
                }

                this.setState({ allDomainsCheckedList: allDomainsCheckedList });
                this.setState({ originalAllDomainsCheckedList: domainsChecked });
                this.populateDomains(domains, allDomainsCheckedList);
                this.setState({ style: 'custom-spinner-hide' });

            })
            .catch(err => {
                this.setState({ allDomainsCheckedList: "" });
                this.populateDomains(domains, "");
                this.setState({ style: 'custom-spinner-hide' });
            });
    }

    populateDomains = (data, allDomainsCheckedList) => {
        // let items = [];
        // data.map((row, index) => {
        //     items.push(
        //         <div key={index} className="domain-checkbox-container">
        //             <div className="checkbox checkbox-primary" >
        //                 <input id={"checkbox-primary-domain" + index} type="checkbox" value={row.domain}
        //                     onChange={e => this.toggleDomain(e, row.domain, row.clientId)} checked={allDomainsCheckedList.includes("&" + (row.domain) + "&")} />
        //                 <label htmlFor={"checkbox-primary-domain" + index}>{row.domain} </label>
        //             </div>
        //         </div>);
        // });

        // this.setState({ domainsList: items });
      

            let columns =[
                {
                    label: 'Id',
                    field: 'id',
                },
                {
                    label: 'Domain',
                    field: 'domain',
                },
                // {
                //     label: 'Client Id',
                //     field: 'clientId',
                // },
                {
                    label: 'Check/Uncheck',
                    field: 'edit',
                    sort: 'disabled',
                }
            ];
    
            let dataModified = null;
            if(data != null)
            {
                dataModified = data.map((row, index)=>{
                    return ({...row, edit:  <div className="checkbox checkbox-primary" >
                    <input id={"checkbox-primary-domain" + index} type="checkbox" value={row.domain.toLowerCase()}
                        data-id={allDomainsCheckedList.includes("&" + (row.domain.toLowerCase()) + "&")}
                            onChange={e => this.toggleDomain(e, row.domain, row.clientId)} checked={allDomainsCheckedList.includes("&" + (row.domain.toLowerCase()) + "&")} />
                        <label htmlFor={"checkbox-primary-domain" + index}></label>
                    </div>,
                    clientId: ""});
                });
    
            }
            this.setState({dataTable: {columns: columns, rows: dataModified}});
            this.setState({loading: false});
        
    }

    toggleDomain = (evt, domain, clientId) => {
        this.setState({ style: 'custom-spinner' });
        let allDomainsCheckedList = this.state.allDomainsCheckedList;
        let flag = false;
        if (evt.target.checked) {
            allDomainsCheckedList += "&" + evt.target.value + "&"
            flag = true
        }
        else {
            //remove
            allDomainsCheckedList = allDomainsCheckedList.replaceAll("&" + evt.target.value + "&", "")
        }

        this.setState({ allDomainsCheckedList: allDomainsCheckedList });
        if (this.state.filteredListOfDomains) {
            this.populateDomains(this.state.filteredListOfDomains, allDomainsCheckedList);
        } else {
            this.populateDomains(this.state.myDomains, allDomainsCheckedList);
        }


        const payLoad = {
            cloud_id: JSON.parse(localStorage.getItem('creds')).cloud_id,
            username: JSON.parse(localStorage.getItem('creds')).username,
            domain: domain,
            flag: flag,
            user_id: JSON.parse(localStorage.getItem('creds')).id,
        }

        axios.post(`${process.env.REACT_APP_API_URL}checkUncheckDomains`, payLoad, this.headers)
            .then(response => {

                toast.success(flag ? "Successfully enabled domain" : "Successfully disabled domain");
                this.setState({ searchValue: "" });
                this.populateDomains(this.state.myDomains, this.state.allDomainsCheckedList, this.state.allTabsCheckedList);
                this.setState({ style: 'custom-spinner-hide' });

            })
            .catch(err => {
                toast.error(err.message);
                this.setState({ style: 'custom-spinner-hide' });
            });
    }

    checkAll = (evt) => {
        if (evt.target.checked) {
            let allDomainsCheckedList = this.state.allDomainsCheckedList;
            for (let i = 0; i < (this.state.myDomains).length; i++) {
                if (allDomainsCheckedList === "") {
                    allDomainsCheckedList = "&" + (this.state.myDomains[i].domain) + "&";
                } else {
                    allDomainsCheckedList += ",&" + (this.state.myDomains[i].domain) + "&";
                }
            }
            this.setState({ allDomainsCheckedList: allDomainsCheckedList });
            this.populateDomains(this.state.myDomains, allDomainsCheckedList);
            this.setState({ checkedAll: true });
        }
        else {
            const data = this.state.originalAllDomainsCheckedList;
            let allDomainsCheckedList = "";
            for (let i = 0; i < data.length; i++) {

                if (allDomainsCheckedList === "") {
                    allDomainsCheckedList = "&" + (data[i].domain) + "&";
                } else {
                    allDomainsCheckedList += ",&" + (data[i].domain) + "&";
                }
            }
            this.setState({ allDomainsCheckedList: allDomainsCheckedList });
            this.populateDomains(this.state.myDomains, allDomainsCheckedList);
            this.setState({ checkedAll: false });
        }
    }

    onChangeHandler = (evt) => {
        this.setState({ [evt.target.name]: evt.target.value });
    }

    searchChangeHandler = (evt) => {
        this.setState({ searchValue: evt.target.value });
        let domains = this.state.myDomains;
        domains = domains.filter((value, index) => {
            const domain = value.domain.toLowerCase();
            const searchResult = evt.target.value.toLowerCase();
            return domain.includes(searchResult);
        });
        this.setState({ filteredListOfDomains: domains });
        this.populateDomains(domains, this.state.allDomainsCheckedList);
    }


    render() {
        return (
            <Fragment>
                <Breadcrumb parent="App" title="Messaging" />

                <div className={"loader-box " + this.state.style} >
                    <div className="loader">
                        <div className="line bg-primary"></div>
                        <div className="line bg-primary"></div>
                        <div className="line bg-primary"></div>
                        <div className="line bg-primary"></div>
                    </div>
                </div>

                <div className = "MainIndex">
                    <div className="users-table">
                    <MDBDataTableV5 hover entriesOptions={[50, 100, 200]} entries={50}
                    pagesAmount={4} data={this.state.dataTable} searchTop  searchBottom={false} />

                    </div>
                </div>
            </Fragment>
        );
    }

};

export default Domains;